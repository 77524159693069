import React from "react";
import Guard from "components/Guard/Guard";
import FontIcon from "components/FontIcon/FontIcon";
import styled, { css } from "styled-components/macro";

type Props = {
  displayName: string;
  isSelected: boolean;
  isLocked: boolean;
  isClickable: boolean;
  isRequired?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler;
};

const ParticipantTypeTag: React.FC<Props> = ({
  displayName,
  isSelected,
  isLocked,
  isClickable,
  isRequired = false,
  disabled = false,
  onClick,
  ...otherProps
}) => {
  return (
    <StyledParticipantTypeTag
      onClick={onClick}
      $isSelected={isSelected}
      $isClickable={isClickable}
      disabled={disabled}
      data-testid={"ParticipantTypeTag"}
      aria-pressed={isSelected}
      {...otherProps}
    >
      <div>
        {displayName}
        <Guard condition={isRequired}>
          <StyledAsterisk>*</StyledAsterisk>
        </Guard>
      </div>
      <Guard condition={isLocked}>
        <FontIcon name="icon-lock" />
      </Guard>
    </StyledParticipantTypeTag>
  );
};

const StyledParticipantTypeTag = styled.button<{ $isClickable: boolean; $isSelected: boolean; disabled: boolean }>(
  ({ theme, $isClickable, $isSelected, disabled }) => css`
    padding: 10px 20px;
    background: ${theme.palette.objective.light.taro};
    border-radius: 39px;
    border: none;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: ${theme.palette.objective.dark.fiordland};
    display: flex;
    align-items: center;
    cursor: ${$isClickable && !disabled ? "pointer" : "default"};
    ${
      $isSelected &&
      css`
        background: ${theme.palette.objective.green.dark};
        color: ${theme.palette.objective.light.white};
      `
    }
    ${
      disabled &&
      css`
        background: ${theme.palette.objective.light.day};
        color: ${theme.palette.objective.light.allspice};
      `
    }
    ${theme.mixins.flexGap("8px")};
  `
);

const StyledAsterisk = styled.span(
  ({ theme }) => css`
    font-size: 16px;
    padding-left: 2px;
  `
);

export default ParticipantTypeTag;
