import React from "react";
import { useStoreDispatch } from "store/hooks";
import { removeQualification } from "store/app-state/participant-buffer/participantBuffer";
import RemoveIconButton from "components/RemoveIconButton/RemoveIconButton";

export const RemoveQualificationButton: React.FC<{ index: number }> = ({ index }) => {
  const dispatch = useStoreDispatch();

  const handleClickRemoveQualification = React.useCallback(() => {
    dispatch(removeQualification({ index }));
  }, [dispatch, index]);

  return <RemoveIconButton onClick={handleClickRemoveQualification} />;
};
