import React from "react";
import FlexBox from "components/FlexBox/FlexBox";
import Guard from "components/Guard/Guard";
import FormikTextInput from "components/FormikTextInput/FormikTextInput";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import Box from "components/Box/Box";
import styled, { css } from "styled-components/macro";
import { useTheme } from "@material-ui/core";
import { formatCommaSeparatedString } from "utils/formatCommaSeparatedString";
import FontIcon from "components/FontIcon/FontIcon";
import FormikTextArea from "components/FormikTextArea/FormikTextArea";

type Props = {
  groupList: string;
  groupName: string;
  isVisible?: boolean;
  hasDivider?: boolean;
};

// TODO: Refactor:
//   1. this is not a globally shared component, should be moved to Participant Edit form, and to be renamed
//   2. it should not give FormikTextArea an empty label -- can extend FormikTextArea with a new props (isLabelHidden)
const FormikGroupInput: React.FC<Props> = ({ groupList, groupName, isVisible = false, hasDivider = true }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { setFieldValue } = useFormikContext<any>();
  const [isGroupInfoVisible, setIsGroupInfoVisible] = React.useState(isVisible);
  const twisterIcon = isGroupInfoVisible ? "chevron-up" : "chevron-down";

  const updateFormValues = React.useCallback(
    (groupList: string, groupName: string) => {
      setFieldValue("groupList", groupList);
      setFieldValue("groupName", groupName);
    },
    [setFieldValue]
  );

  React.useEffect(() => {
    updateFormValues(groupList, groupName);
  }, [groupList, groupName, updateFormValues]);

  const toggleGroupInputHandler = React.useCallback(() => {
    setIsGroupInfoVisible(!isGroupInfoVisible);
  }, [isGroupInfoVisible]);

  return (
    <FlexBox direction="column" spacing={1} data-testid={"FormikGroupInput"}>
      <StyledToggleAddGroup spacing={2} onClick={toggleGroupInputHandler}>
        <FlexBox>
          <StyledToggleAddGroupInput data-testid={"FormikGroupInputToggle"}>
            {t(`Add a group of people`)}
          </StyledToggleAddGroupInput>
        </FlexBox>
        <StyledToggleAddGroupInputLine flexGrow={1}>&nbsp;</StyledToggleAddGroupInputLine>
        <FlexBox flexShrink={1}>
          <FontIcon name={twisterIcon} color={theme.palette.objective.dark.fiordland} fontSize={18} />
        </FlexBox>
      </StyledToggleAddGroup>
      <Guard condition={isGroupInfoVisible}>
        <StyledRightAlignedInfo>{t("To bulk add, comma separate names")}</StyledRightAlignedInfo>
        <StyledGroupInfoContainer
          $hasDivider={hasDivider}
          direction="column"
          spacing={6}
          data-testid={"FormikGroupInfoInput"}
        >
          <FormikTextArea
            label={""}
            fieldName="groupList"
            rows={2}
            onBlur={(evt) => {
              const formattedCsv = formatCommaSeparatedString(evt.target.value);
              setFieldValue("groupList", formattedCsv);
            }}
          />
          <FormikTextInput label={t(`Group name (optional)`)} fieldName="groupName" />
        </StyledGroupInfoContainer>
      </Guard>
    </FlexBox>
  );
};

const StyledGroupInfoContainer = styled(FlexBox)<{ $hasDivider: boolean }>(
  ({ theme, $hasDivider }) => css`
    ${$hasDivider &&
    css`
      padding-bottom: 24px;
      border-bottom: 1px solid ${theme.palette.objective.light.allspice};
    `}
    ${!$hasDivider &&
    css`
      padding-bottom: 0;
      border-bottom: 0px solid transparent;
    `}
  `
);
const StyledToggleAddGroup = styled(FlexBox)(
  ({ theme }) => css`
    cursor: pointer;
  `
);

const StyledToggleAddGroupInput = styled.span(
  ({ theme }) => css`
    background: ${theme.palette.objective.light.white};
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: ${theme.palette.objective.blue.main};
  `
);
const StyledToggleAddGroupInputLine = styled(Box)(
  ({ theme }) => css`
    width: 100%;
    border-bottom: 1px solid ${theme.palette.objective.light.allspice};
    line-height: 0.2em;
    margin-bottom: 8px;
  `
);

const StyledRightAlignedInfo = styled(Box)(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    font-weight: 400;
    color: ${theme.palette.objective.dark.neutral};
  `
);

export default FormikGroupInput;
