import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import { serviceContainer } from "services/serviceContainer";
import isEmpty from "lodash/isEmpty";
import { createDeepEqualSelector } from "store/utils";
import { IRequiredDocumentGroupEntity, RequiredDocumentGroupUtil } from "models/RequiredDocumentGroup.model";

// Entity Adapter

const requiredDocumentGroupAdapter = createEntityAdapter<IRequiredDocumentGroupEntity>({
  selectId: (requiredDocumentGroup) => requiredDocumentGroup.guid,
  sortComparer: RequiredDocumentGroupUtil.groupComparator,
});

// Action & Thunks

export const fetchRequiredDocumentGroupsByApplicationId = createAsyncThunk(
  "domainData/requiredDocumentGroups/fetchRequiredDocumentGroupsByApplicationId",
  async (applicationId: number, thunkAPI) => {
    const requiredDocumentGroups = await serviceContainer.cradle.requiredDocumentsService.fetchRequiredDocumentGroups(
      applicationId
    );
    if (isEmpty(requiredDocumentGroups)) {
      return;
    }
    thunkAPI.dispatch(loadApplicationRequiredDocumentGroups(requiredDocumentGroups));
  }
);

export const loadApplicationRequiredDocumentGroups = createAction<IRequiredDocumentGroupEntity[]>(
  "domainData/requiredDocumentGroups/loadRequiredDocumentGroups"
);

// Reducer

export const defaultApplicationRequiredDocumentGroupsState = requiredDocumentGroupAdapter.getInitialState();

export const applicationRequiredDocumentGroupsReducer = createReducer<
  typeof defaultApplicationRequiredDocumentGroupsState
>(defaultApplicationRequiredDocumentGroupsState, (builder) =>
  builder.addCase(loadApplicationRequiredDocumentGroups, requiredDocumentGroupAdapter.upsertMany)
);

// Selectors

export const {
  selectAll: selectAllApplicationRequiredDocumentGroupEntities,
  selectById: selectRequiredDocumentGroupById,
} = requiredDocumentGroupAdapter.getSelectors((state: RootState) => state.domainData.requiredDocumentGroups);

export const selectRequiredDocumentGroupsByApplicationId = createDeepEqualSelector(
  [
    (state: RootState) => selectAllApplicationRequiredDocumentGroupEntities(state),
    (state: RootState, applicationId: number) => applicationId,
  ],
  (requiredDocumentGroups, applicationId) => {
    const applicationRequiredDocumentGroups = requiredDocumentGroups.filter(
      (group) => group.applicationId === applicationId
    );
    return applicationRequiredDocumentGroups;
  }
);

export const selectOrderedBuildingsForRequiredDocumentGroupsInApplication = createDeepEqualSelector(
  [(state: RootState, applicationId: number) => selectRequiredDocumentGroupsByApplicationId(state, applicationId)],
  (requiredDocumentGroups) => {
    const buildingsAllCases = Array.from(new Set(requiredDocumentGroups.map((group) => group.building)));
    const buildings: string[] = [];
    for (const buildingAllCases of buildingsAllCases) {
      const isFound = buildings.some((building) => buildingAllCases.toLowerCase() === building.toLowerCase());
      if (!isFound) {
        buildings.push(buildingAllCases);
      }
    }
    return buildings;
  }
);

type SelectRequiredDocumentGroupsByApplicationIdAndBuildingProps = {
  applicationId: number;
  building: string;
};
export const selectRequiredDocumentGroupsByApplicationIdAndBuilding = createDeepEqualSelector(
  [
    (state: RootState, props: SelectRequiredDocumentGroupsByApplicationIdAndBuildingProps) => props.building,
    (state: RootState, props: SelectRequiredDocumentGroupsByApplicationIdAndBuildingProps) =>
      selectRequiredDocumentGroupsByApplicationId(state, props.applicationId),
  ],
  (building, requiredDocumentGroups) => {
    const requireDocumentGroupsByBuilding = requiredDocumentGroups.filter(
      (group) => group.building.toLowerCase() === building.toLowerCase()
    );
    return requireDocumentGroupsByBuilding;
  }
);
