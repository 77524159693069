import { snakeCase } from "lodash";
import { base64Decode } from "utils/base64Decode";
import { base64Encode } from "utils/base64Encode";
import hashSum from "hash-sum";

export type IApplicationFilters = {
  forms: IApplicationFilterItem[];
  authorities: IApplicationFilterItem[];
  statuses: IApplicationFilterItem[];
  complexities: IApplicationFilterItem[];
  outstandingActions: IApplicationFilterItem[];
};

export type IApplicationFilterItem = {
  id: string;
  name: string;
  group: ApplicationFilterGroup;
};

export enum ApplicationFilterGroup {
  BCA = "BCA",
  Form = "Form",
  Status = "Status",
  Complexity = "Complexity",
  OutstandingAction = "OutstandingAction",
}

export const ApplicationFilterItemUtil = {
  generateKey: (item: IApplicationFilterItem) => {
    const key = snakeCase(`${item.group}_${item.id}_${item.name}`);
    return key;
  },
  generateKeyHash: (item: IApplicationFilterItem) => {
    const str = ApplicationFilterItemUtil.generateKey(item);
    const hash = hashSum(str); // Generate four-byte short hash
    return hash;
  },
  isEqual: (leftItem: IApplicationFilterItem, rightItem: IApplicationFilterItem) => {
    return ApplicationFilterItemUtil.generateKey(leftItem) === ApplicationFilterItemUtil.generateKey(rightItem);
  },
  includes: (items: IApplicationFilterItem[], targetItem: IApplicationFilterItem) => {
    return Boolean(items.find((item) => ApplicationFilterItemUtil.isEqual(item, targetItem)));
  },
  getGroupedFilterItemIds: (items: IApplicationFilterItem[]) => {
    const groupedFilterItemIds = {
      [ApplicationFilterGroup.BCA]: items
        .filter((item) => item.group === ApplicationFilterGroup.BCA)
        .map((item) => item.id),
      [ApplicationFilterGroup.Complexity]: items
        .filter((item) => item.group === ApplicationFilterGroup.Complexity)
        .map((item) => item.id),
      [ApplicationFilterGroup.Form]: items
        .filter((item) => item.group === ApplicationFilterGroup.Form)
        .map((item) => item.id),
      [ApplicationFilterGroup.Status]: items
        .filter((item) => item.group === ApplicationFilterGroup.Status)
        .map((item) => item.id),
      [ApplicationFilterGroup.OutstandingAction]: items
        .filter((item) => item.group === ApplicationFilterGroup.OutstandingAction)
        .map((item) => item.id),
    };

    return groupedFilterItemIds;
  },
  encodeFilterItems: (items: IApplicationFilterItem[]) => {
    const filterKeys = items.map(ApplicationFilterItemUtil.generateKeyHash).join(",");
    const encodedStr = base64Encode(filterKeys);
    return encodedStr;
  },
  decodeFilterItems: (encodedString: string, filters: IApplicationFilters): IApplicationFilterItem[] => {
    if (!encodedString) {
      return [];
    }

    const base64DecodedStr = base64Decode(encodedString);
    const filterKeys = base64DecodedStr.split(",");

    const availableFilterItems = [
      ...filters.forms,
      ...filters.authorities,
      ...filters.statuses,
      ...filters.complexities,
      ...filters.outstandingActions,
    ];
    const filterItems = availableFilterItems.filter((item) => {
      return filterKeys.includes(ApplicationFilterItemUtil.generateKeyHash(item));
    });

    return filterItems;
  },
};
