import React from "react";
import styled, { css } from "styled-components/macro";
import { Tab, Tabs, useTheme } from "@material-ui/core";
import Guard from "components/Guard/Guard";
import { useTranslation } from "react-i18next";
import ParticipantPersonalInfoForm from "components/ParticipantEditDialog/ParticipantPersonalInfoForm";
import { unwrapResult } from "@reduxjs/toolkit";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import ParticipantTypeAndAccessForm from "components/ParticipantEditDialog/ParticipantTypeAndAccessForm";
import { useToast } from "hooks/useToast";
import {
  createParticipant,
  fetchParticipants,
  selectParticipantTypesOfCurrentUserOnApplicationBasedOnApplicationId,
  updateParticipant,
} from "store/domain-data/participant/participant";
import { selectMustInviteSelectedParticipantTypes } from "store/domain-data/participant-type/participantType";
import {
  selectCurrentParticipant,
  selectIsParticipantChanged,
  selectIsParticipantQualificationsValid,
  selectParticipantType,
  setAccessLevel,
  startEditParticipant,
} from "store/app-state/participant-buffer/participantBuffer";
import isEmpty from "lodash/isEmpty";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FontIcon from "components/FontIcon/FontIcon";
import Box from "components/Box/Box";
import { InvitationStatus, IParticipantEntity, IParticipantTypeApplicationEntity } from "models/Participant.model";
import { createContact, fetchContactsForCurrentUser } from "store/domain-data/contact/contact";
import Dialog from "components/odl-v2/Dialog/Dialog";
import DialogContent from "components/odl-v2/Dialog/DialogContent";
import DialogActions from "components/odl-v2/Dialog/DialogActions";
import DialogActionCancel from "components/odl-v2/Dialog/DialogActionCancel";
import DialogActionGroup from "components/odl-v2/Dialog/DialogActionGroup";
import DialogActionSecondary from "components/odl-v2/Dialog/DialogActionSecondary";
import DialogActionPrimary from "components/odl-v2/Dialog/DialogActionPrimary";
import { fetchQualificationTypes } from "store/domain-data/qualification-type/qualificationType";
import ParticipantQualificationList from "./ParticipantQualificationList";
import omit from "lodash/omit";
import AlertIcon from "components/AlertIcon/AlertIcon";
import ButtonLoadingIcon from "components/odl-v2/Button/ButtonLoadingIcon";
import { selectIsLoadingThunks } from "store/app-state/loading/loading";
import { usePostHog } from "posthog-js/react";

export enum ParticipantEditDialogStep {
  UserDetails,
  RolesAndAccess,
  Qualification,
}

type Props = {
  applicationId: number;
  participantId: number; // If participantId is 0, it means we are going to create a participant
  initialStep: ParticipantEditDialogStep;
  initialType?: IParticipantTypeApplicationEntity;
  onClose: () => any;
};

const ParticipantEditDialog: React.FC<Props> = ({
  applicationId,
  participantId,
  initialStep,
  initialType,
  onClose,
}) => {
  // Common
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const { toastSuccess, toastError } = useToast();
  const theme = useTheme();
  const posthog = usePostHog();

  const participantTypesOfCurrentUserOnApplicationBasedOnApplicationId = useStoreSelector((state) =>
    selectParticipantTypesOfCurrentUserOnApplicationBasedOnApplicationId(state, applicationId)
  );

  // Derived data
  const [dialogInitialised, setDialogInitialised] = React.useState(false);

  const currentParticipant = useStoreSelector((state) => selectCurrentParticipant(state));

  const mustInviteSelectedParticipantsTypes = useStoreSelector((state) =>
    selectMustInviteSelectedParticipantTypes(state, { selectedParticipantTypes: currentParticipant.participantTypes })
  );

  const isParticipantChanged = useStoreSelector(selectIsParticipantChanged);

  const isQualificationsValid = useStoreSelector(selectIsParticipantQualificationsValid);

  const isNewParticipant = React.useMemo(() => !Boolean(participantId), [participantId]);

  const isLoading = useStoreSelector((state) => selectIsLoadingThunks(state, [createParticipant, updateParticipant]));

  const [tab, setTab] = React.useState(0);
  const [step, setStep] = React.useState(initialStep);

  // Form defaults to invalid for new participants
  const [isFormValid, setIsFormValid] = React.useState(!isNewParticipant);

  const [isAddContactSwitchOn, setIsAddContactSwitchOn] = React.useState(false);

  const hasNextStep = React.useMemo(() => {
    const numberOfSteps = Object.keys(ParticipantEditDialogStep).length / 2;
    return step < numberOfSteps - 1;
  }, [step]);

  const isPersonalInfoValid = React.useMemo(() => {
    return isFormValid && (!!currentParticipant.email || isEmpty(mustInviteSelectedParticipantsTypes));
  }, [isFormValid, currentParticipant.email, mustInviteSelectedParticipantsTypes]);

  const isSaveAllowed = React.useMemo(() => {
    return isPersonalInfoValid && isParticipantChanged && isQualificationsValid;
  }, [isPersonalInfoValid, isParticipantChanged, isQualificationsValid]);

  const hasInvitationBeenSent = React.useCallback(
    (savedParticipant: IParticipantEntity) => {
      // Display success toast when participant invite status currently none or error, and changed to sent
      return (
        (currentParticipant.invitation === InvitationStatus.None ||
          currentParticipant.invitation === InvitationStatus.Error) &&
        (savedParticipant.invitation === InvitationStatus.Unregistered ||
          savedParticipant.invitation === InvitationStatus.Queued ||
          savedParticipant.invitation === InvitationStatus.Accepted)
      );
    },
    [currentParticipant.invitation]
  );

  const hasInvitationFailed = React.useCallback(
    (savedParticipant: IParticipantEntity) => {
      // Display error toast when participant invite status not already error, and becomes error
      return (
        currentParticipant.invitation !== InvitationStatus.Error &&
        savedParticipant.invitation === InvitationStatus.Error
      );
    },
    [currentParticipant.invitation]
  );

  // Callbacks
  const saveParticipant = React.useCallback(async () => {
    const payload = {
      ...currentParticipant,
      participantTypes: currentParticipant.participantTypes.map((type) => type.name),
      qualifications: currentParticipant.qualifications.map((item) => omit(item, "isValid")),
    };

    if (isNewParticipant) {
      return await dispatch(createParticipant({ applicationId, payload: payload })).then(unwrapResult);
    } else {
      return await dispatch(updateParticipant({ applicationId, participantId, payload: payload })).then(unwrapResult);
    }
  }, [currentParticipant, isNewParticipant, dispatch, applicationId, participantId]);

  const saveContact = React.useCallback(async () => {
    const payload = {
      firstName: currentParticipant.firstName,
      lastName: currentParticipant.lastName,
      organisation: currentParticipant.organisation,
      phoneCountryCode: currentParticipant.phoneCountryCode,
      phone: currentParticipant.phone,
      email: currentParticipant.email,
      address1: currentParticipant.address.address1,
      address2: currentParticipant.address.address2,
      city: currentParticipant.address.city,
      state: currentParticipant.address.state,
      country: currentParticipant.address.country,
      zipCode: currentParticipant.address.zipCode,
      fullAddress: currentParticipant.address.fullAddress,
      isManualAddress: currentParticipant.address.isManualAddress,
    };

    return await dispatch(createContact(payload)).then(unwrapResult);
  }, [currentParticipant, dispatch]);

  const handleClickFinish = React.useCallback(async () => {
    if (!isSaveAllowed) {
      return;
    }

    try {
      const savedParticipant = await saveParticipant();

      if (hasInvitationBeenSent(savedParticipant)) {
        toastSuccess(t(`Invitation has been sent to {{email}}`, { email: savedParticipant.email }));
        posthog?.capture(`user sent-participant-invitation`, {
          participantTypes: participantTypesOfCurrentUserOnApplicationBasedOnApplicationId || "non-participant",
        });
      } else if (hasInvitationFailed(savedParticipant)) {
        toastError(
          t(`Invitation has failed to send to {{email}}. Please try to re-send later`, {
            email: savedParticipant.email,
          })
        );
      }

      if (isAddContactSwitchOn) {
        await saveContact();
        toastSuccess(t("New contact has been added to library"));

        posthog?.capture(`user added-participant-to-contacts`, {
          participantTypes: participantTypesOfCurrentUserOnApplicationBasedOnApplicationId || "non-participant",
        });
      }

      // Load updated participants (BE handles migrating participant types between participants)
      await dispatch(fetchParticipants({ applicationId })).then(unwrapResult);

      onClose();
    } catch (e) {
      toastError(t(`Failed to save participant. Please try again later`));
    }
  }, [
    isSaveAllowed,
    saveParticipant,
    hasInvitationBeenSent,
    hasInvitationFailed,
    isAddContactSwitchOn,
    dispatch,
    applicationId,
    onClose,
    toastSuccess,
    t,
    posthog,
    participantTypesOfCurrentUserOnApplicationBasedOnApplicationId,
    toastError,
    saveContact,
  ]);

  const handleClickNext = React.useCallback(async () => {
    if (!hasNextStep) {
      return;
    }
    setStep((step) => step + 1);
  }, [hasNextStep]);

  const handleClickBack = React.useCallback(() => {
    setStep((step) => Math.max(step - 1, 0));
  }, []);

  const handleFormValidated = React.useCallback((valid: boolean) => {
    setIsFormValid(valid);
  }, []);

  const handleContactSwitchChange = React.useCallback((enabled: boolean) => {
    setIsAddContactSwitchOn(enabled);
  }, []);

  const handleChangeStep = React.useCallback(
    (toggledStep: ParticipantEditDialogStep) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      if (step !== toggledStep) {
        setStep(toggledStep);
      } else {
        if (!hasNextStep) {
          handleClickBack();
        }
        handleClickNext();
      }
    },
    [step, hasNextStep, setStep, handleClickBack, handleClickNext]
  );

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  React.useEffect(() => {
    setDialogInitialised(false);
    const initData = async () => {
      await dispatch(startEditParticipant({ applicationId, participantId: participantId || undefined }));
      if (initialType && initialType.minimumAccessLevel) {
        dispatch(selectParticipantType(initialType));
        dispatch(setAccessLevel(initialType.minimumAccessLevel));
      }
      await dispatch(fetchContactsForCurrentUser());
      await dispatch(fetchQualificationTypes(applicationId));
      setDialogInitialised(true);
    };
    initData();
  }, [applicationId, participantId, initialType, dispatch]);

  return (
    <Dialog
      open={true}
      data-testid={"ParticipantEditDialog"}
      title={isNewParticipant ? t(`Add a person to the application`) : t(`Edit a person on the application`)}
      onClose={onClose}
    >
      {dialogInitialised && (
        <StyledDialogContent>
          <Guard condition={isNewParticipant}>
            <StyledTabs value={tab} onChange={handleChangeTab} centered>
              <StyledTab
                data-testid="tab-addNew"
                label={
                  <Box fontSize={14} color={theme.palette.objective.dark.night}>
                    <FontIcon name={"account-star"} fontSize={14} color={theme.palette.objective.dark.night} />
                    <Box component="span" paddingLeft={"4px"}>
                      {t(`Add new`)}
                    </Box>
                  </Box>
                }
              />
            </StyledTabs>
          </Guard>
          <StyledAccordion
            square={true}
            data-testid={"ParticipantUserDetailsPanel"}
            expanded={step === ParticipantEditDialogStep.UserDetails}
            onChange={handleChangeStep(ParticipantEditDialogStep.UserDetails)}
          >
            <StyledAccordionSummary
              expandIcon={<FontIcon name={"chevron-down"} fontSize={18} padding={2} />}
              aria-controls={`user-details-content`}
              id={`user-details-header`}
            >
              <Box>
                {t(`User details`)}
                <Guard condition={isPersonalInfoValid}>
                  <StyledCheckIcon name={"check"} fontSize={18} padding={2} />
                </Guard>
                <Guard condition={!isPersonalInfoValid}>
                  <AlertIcon aria-label={"Participants user details missing for mandatory role"} />
                </Guard>
              </Box>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <ParticipantPersonalInfoForm
                onFormValidated={handleFormValidated}
                onChangeSaveContact={handleContactSwitchChange}
                isSaveContactSelected={isAddContactSwitchOn}
              />
            </StyledAccordionDetails>
          </StyledAccordion>
          <StyledAccordion
            square={true}
            data-testid={"ParticipantRoleAndAccessPanel"}
            expanded={step === ParticipantEditDialogStep.RolesAndAccess}
            onChange={handleChangeStep(ParticipantEditDialogStep.RolesAndAccess)}
          >
            <StyledAccordionSummary
              expandIcon={<FontIcon name={"chevron-down"} fontSize={18} padding={2} />}
              aria-controls={`roles-and-access-content`}
              id={`roles-and-access-header`}
            >
              {t(`Roles and access`)}
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <ParticipantTypeAndAccessForm />
            </StyledAccordionDetails>
          </StyledAccordion>
          <StyledAccordion
            square={true}
            data-testid={"ParticipantQualificationPanel"}
            expanded={step === ParticipantEditDialogStep.Qualification}
            onChange={handleChangeStep(ParticipantEditDialogStep.Qualification)}
          >
            <StyledAccordionSummary
              expandIcon={<FontIcon name={"chevron-down"} fontSize={18} padding={2} />}
              aria-controls={`roles-and-access-content`}
              id={`roles-and-access-header`}
            >
              {t(`Trade license or qualification`)}
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <ParticipantQualificationList />
            </StyledAccordionDetails>
          </StyledAccordion>
        </StyledDialogContent>
      )}

      <DialogActions>
        <DialogActionCancel data-testid={"ParticipantCancelButton"} onClick={onClose}>
          {t(`Cancel`)}
        </DialogActionCancel>
        <DialogActionGroup>
          <Guard condition={hasNextStep}>
            <DialogActionSecondary
              data-testid={"ParticipantFinishButton"}
              onClick={handleClickFinish}
              disabled={!isSaveAllowed || isLoading}
              endIcon={isLoading ? <ButtonLoadingIcon /> : null}
            >
              {t(`Finish`)}
            </DialogActionSecondary>
          </Guard>
          <Guard condition={hasNextStep}>
            <DialogActionPrimary data-testid={"ParticipantNextButton"} onClick={handleClickNext}>
              {t(`Next`)}
            </DialogActionPrimary>
          </Guard>
          <Guard condition={!hasNextStep}>
            <DialogActionPrimary
              data-testid={"ParticipantFinishButton"}
              onClick={handleClickFinish}
              disabled={!isSaveAllowed || isLoading}
              endIcon={isLoading ? <ButtonLoadingIcon /> : null}
            >
              {t(`Done`)}
            </DialogActionPrimary>
          </Guard>
        </DialogActionGroup>
      </DialogActions>
    </Dialog>
  );
};

const StyledTabs = styled(Tabs)(
  ({ theme }) => css`
    margin-top: 12px;
    margin-bottom: 16px;

    & .MuiTabs-indicator {
      background-color: ${theme.palette.objective.blue.main};
      height: 4px;
    }
  `
);

const StyledTab = styled(Tab)(
  ({ theme }) => css`
    min-width: auto;
    padding: 0;
    line-height: normal;
  `
);

const StyledAccordion = styled(Accordion)(
  ({ theme }) => css`
    border-top: 1px solid ${theme.palette.objective.light.allspice};
    box-shadow: none;

    &.Mui-expanded {
      margin: 0px;
    }
  `
);

const StyledAccordionSummary = styled(AccordionSummary)(
  ({ theme }) => css`
    background-color: ${theme.palette.objective.light.day};
    margin: 0px;
    min-height: 58px;
    padding: 0 43px 0 22px;
    font-size: 16px;

    &.Mui-expanded {
      color: ${theme.palette.objective.dark.neutral};
      margin: 0px;
      min-height: 58px;
    }
  `
);

const StyledAccordionDetails = styled(AccordionDetails)(
  ({ theme }) => css`
    display: block;
    background-color: ${theme.palette.objective.light.white};
    padding: 0px;
  `
);

const StyledCheckIcon = styled(FontIcon)(
  ({ theme }) => css`
    color: ${theme.palette.objective.green.main};
    font-weight: 600;
  `
);

const StyledDialogContent = styled(DialogContent)(
  ({ theme }) => css`
    padding: 0;
    max-width: 670px;
  `
);

export default ParticipantEditDialog;
