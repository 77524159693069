import React from "react";
import { useFormikContext } from "formik";
import FormikTextInput from "components/FormikTextInput/FormikTextInput";
import trim from "lodash/trim";

type Props = {
  label: string;
  fieldName: string;
  disabled?: boolean;
  required?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
};

const FormikEmailInput: React.FC<Props> = ({ label, fieldName, disabled = false, required = false, onBlur }) => {
  const { setFieldValue } = useFormikContext<any>();

  const handleBlur = React.useCallback(
    (evt: React.FocusEvent<HTMLInputElement>) => {
      // Trim text input
      const emailAddress = trim(evt.target.value).toLowerCase();
      setFieldValue(fieldName, emailAddress);

      // Custom blur handler
      if (onBlur) {
        onBlur(evt);
      }
    },
    [onBlur, setFieldValue, fieldName]
  );

  return (
    <FormikTextInput
      label={label}
      fieldName={fieldName}
      disabled={disabled}
      required={required}
      inputType={"text"}
      onBlur={handleBlur}
    />
  );
};

export default FormikEmailInput;
