import React from "react";
import { capitalize, FormControl, FormHelperText, InputLabel, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { useStoreSelector } from "store/hooks";
import { selectLicensingClassesByQualificationName } from "store/domain-data/qualification-type/qualificationType";
import OptionsManagementCombo from "components/ParticipantEditDialog/OptionsManagementCombo";
import { IQualificationEntity } from "models/Qualification.model";
import get from "lodash/get";
import styled, { css } from "styled-components/macro";
import { QualificationFormField } from "components/ParticipantEditDialog/models/QualificationFormField";

type Props = {};

const ParticipantQualificationLicenseClasses: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { touched, errors, values, setFieldValue, setFieldTouched } = useFormikContext<IQualificationEntity>();
  const licensingClasses = useStoreSelector((state) =>
    selectLicensingClassesByQualificationName(state, values.qualificationName)
  );

  const availableOptions = React.useMemo(() => {
    return licensingClasses.map((item) => {
      return {
        name: item.name,
        displayName: item.displayName,
      };
    });
  }, [licensingClasses]);

  const selectedOptions = React.useMemo(() => {
    return availableOptions.filter((option) => values.licensingClasses.includes(option.name));
  }, [availableOptions, values.licensingClasses]);

  const handleChangeSelectedOptions = React.useCallback<
    React.ComponentProps<typeof OptionsManagementCombo>["onChangeSelectedOptions"]
  >(
    (options) => {
      const names = options.map((option) => option.name);
      if (JSON.stringify(names) === JSON.stringify(values.licensingClasses)) {
        return;
      }

      setFieldTouched(QualificationFormField.LicensingClasses, true);
      setFieldValue(QualificationFormField.LicensingClasses, names);
    },
    [setFieldTouched, setFieldValue, values.licensingClasses]
  );

  const hasError =
    get(touched, QualificationFormField.LicensingClasses) &&
    Boolean(get(errors, QualificationFormField.LicensingClasses));
  const error = get(errors, QualificationFormField.LicensingClasses);

  const handleRenderInput = React.useCallback(
    (inputParams: any) => {
      return (
        <FormControl
          fullWidth={true}
          error={hasError}
          hiddenLabel={true}
          required={true}
          disabled={false}
          variant={"outlined"}
        >
          <InputLabel {...get(inputParams, "InputLabelProps")} shrink={false}>
            {t("License classes")}
          </InputLabel>

          <StyledTextField
            {...inputParams}
            variant={"outlined"}
            placeholder={t("Search or select licensing classes")}
          />
          {hasError && (
            <FormHelperText
              error={true}
              data-testid={`InputError${capitalize(QualificationFormField.LicensingClasses)}`}
            >
              {error}
            </FormHelperText>
          )}
        </FormControl>
      );
    },
    [hasError, t, error]
  );

  return (
    <OptionsManagementCombo
      availableOptions={availableOptions}
      selectedOptions={selectedOptions}
      renderInput={handleRenderInput}
      onChangeSelectedOptions={handleChangeSelectedOptions}
    />
  );
};

const StyledTextField = styled(TextField)(
  ({ theme }) => css`
    .MuiOutlinedInput-root {
      padding: 18px 14px;
    }
  `
);

export default ParticipantQualificationLicenseClasses;
