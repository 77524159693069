import { IAddressEntity } from "models/Address.model";
import { DEFAULT_PHONE_COUNTRY_CODE } from "constants/configs";

export type IContactEntity = {
  id: number;
  firstName: string;
  lastName: string;
  organisation: string;
  phoneCountryCode: string;
  phone: string;
  email: string;
  address: IAddressEntity;
  lastUsed: number; // TODO: change this to date/string
};

// TODO: Infer from yup schema
export type IContactPersonalInfoFormValues = Omit<IContactEntity, "id" | "lastUsed" | "address"> & IAddressEntity;

export const ContactUtil = {
  getDisplayName: (contact?: IContactEntity): string => {
    if (!contact) {
      return "";
    }
    const displayName = `${contact.firstName} ${contact.lastName}`.trim();
    return displayName;
  },

  getInitials: (contact?: IContactEntity) => {
    if (!contact) {
      return "";
    }

    return [contact.firstName, contact.lastName]
      .filter((str) => str && str.length > 0)
      .map((str) => str[0].toUpperCase())
      .join("");
  },

  convertToFormValues: (contact?: IContactEntity): IContactPersonalInfoFormValues => {
    return {
      firstName: contact?.firstName || "",
      lastName: contact?.lastName || "",
      email: contact?.email || "",
      phoneCountryCode: contact?.phoneCountryCode || DEFAULT_PHONE_COUNTRY_CODE,
      phone: contact?.phone || "",
      organisation: contact?.organisation || "",
      address1: contact?.address.address1 || "",
      address2: contact?.address.address2 || "",
      city: contact?.address.city || "",
      state: contact?.address.state || "",
      country: contact?.address.country || "",
      zipCode: contact?.address.zipCode || "",
      fullAddress: contact?.address.fullAddress || "",
      isManualAddress: contact?.address.isManualAddress || false,
    };
  },
};
