import React from "react";
import FlexBox from "components/FlexBox/FlexBox";
import Guard from "components/Guard/Guard";
import { Autocomplete } from "@material-ui/lab";
import { capitalize, FormControl, FormHelperText, InputLabel, Paper } from "@material-ui/core";
import FormikTextInput from "components/FormikTextInput/FormikTextInput";
import { useFormikContext } from "formik";
import { AddressUtil, IAddressEntity, IAddressSuggestionEntity } from "models/Address.model";
import { serviceContainer } from "services/serviceContainer";
import { useTranslation } from "react-i18next";
import { nanoid } from "@reduxjs/toolkit";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import trim from "lodash/trim";
import LabelledCheckbox from "components/LabelledCheckbox/LabelledCheckbox";
import styled, { css } from "styled-components/macro";
import OutlinedInput from "components/OutlinedInput/OutlinedInput";
import { useScreenWidthMatch } from "hooks/useScreenWidthMatch";

type Props = {
  initAddress?: IAddressEntity;
  label?: string;
  isRequired?: boolean;
};

const FormikAddressInput: React.FC<Props> = ({ initAddress, label = "Address", isRequired = false }) => {
  const { t } = useTranslation();
  const { values, setFieldValue, errors } = useFormikContext<IAddressEntity>();

  const [addressSearchInputValue, setAddressSearchInputValue] = React.useState("");
  const [isOptionListOpen, setIsOptionListOpen] = React.useState(false);
  const [addressOptions, setAddressOptions] = React.useState<IAddressSuggestionEntity[]>([]);
  const inputId = React.useMemo(() => {
    return "address-" + nanoid(5);
  }, []);
  const latestSelectedAddressRef = React.useRef<IAddressEntity | undefined>();
  const isAutocompleteInputFocusedRef = React.useRef(false);

  const screenWidthMatch = useScreenWidthMatch();

  // - Callbacks

  // Update form values
  const clearFormValues = React.useCallback(() => {
    const addressFields = ["address1", "address2", "city", "state", "country", "zipCode", "fullAddress"];
    for (const field of addressFields) {
      setFieldValue(field, "");
    }
  }, [setFieldValue]);

  const updateFormValues = React.useCallback(
    (address: IAddressEntity) => {
      for (const [key, value] of Object.entries(address)) {
        setFieldValue(key, value);
      }
    },
    [setFieldValue]
  );

  // Address search
  const debouncedSearch = React.useMemo(
    () =>
      debounce(async (keywords: string) => {
        try {
          const options = await serviceContainer.cradle.addressCheckerService.search(keywords);

          // If autocomplete input is not focused anymore, we drop the result
          if (!isAutocompleteInputFocusedRef.current) {
            return;
          }

          setAddressOptions(options);
          setIsOptionListOpen(true);
        } catch (e) {
          serviceContainer.cradle.logger.error(e);
        }
      }, 1000),
    []
  );

  const debouncedLookupAddress = React.useCallback((keywords: string) => debouncedSearch(keywords), [debouncedSearch]);

  const clearAddressSearch = React.useCallback(() => {
    setAddressSearchInputValue("");
  }, [setAddressSearchInputValue]);

  const resetAddressSearch = React.useCallback(() => {
    if (!latestSelectedAddressRef.current) {
      clearAddressSearch();
      return;
    }

    setAddressSearchInputValue(latestSelectedAddressRef.current.fullAddress);
    updateFormValues(latestSelectedAddressRef.current);
  }, [clearAddressSearch, updateFormValues]);

  const handleAddressSearchInputChange = React.useCallback(
    (event: any, value: string, reason: string) => {
      setAddressSearchInputValue(value);

      if (!value || value.length < 2 || reason === "reset") {
        return;
      }

      debouncedLookupAddress(value);
    },
    [debouncedLookupAddress]
  );

  const handleAddressSearchValueChange = React.useCallback(
    async (event: any, value: any, reason: string) => {
      setIsOptionListOpen(false);
      clearFormValues();

      if (!value) {
        return;
      }

      // Look up address details when selecting one option from autocomplete input
      const address = await serviceContainer.cradle.addressCheckerService.lookup(value.identifier);
      latestSelectedAddressRef.current = address;
      updateFormValues(address);
    },
    [clearFormValues, updateFormValues]
  );

  const handleAddressSearchInputBlur = React.useCallback(
    (evt: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setIsOptionListOpen(false);

      // If input value is empty, clear the address details
      if (!trim(evt.target.value)) {
        latestSelectedAddressRef.current = undefined;
        clearAddressSearch();
        clearFormValues();
        return;
      }

      // If input value is not empty, reset address details
      resetAddressSearch();
    },
    [resetAddressSearch, clearFormValues, clearAddressSearch]
  );

  // Toggle manual data entry
  const handleSelectIsManualAddress = React.useCallback(() => {
    setFieldValue("isManualAddress", true);
    latestSelectedAddressRef.current = undefined;
    clearAddressSearch();
  }, [setFieldValue, clearAddressSearch]);

  const handleDeselectIsManualAddress = React.useCallback(() => {
    setFieldValue("isManualAddress", false);
    clearFormValues();
    clearAddressSearch();
  }, [setFieldValue, clearFormValues, clearAddressSearch]);

  // - Effects

  // Reset autocomplete and form values when init / re-init
  React.useEffect(() => {
    if (!initAddress) {
      return;
    }

    if (!initAddress.isManualAddress) {
      latestSelectedAddressRef.current = initAddress;
      resetAddressSearch();
    }

    updateFormValues(initAddress);
  }, [initAddress, updateFormValues, resetAddressSearch]);

  // Re-calculate full address for manual data entry
  React.useEffect(() => {
    if (!values.isManualAddress) {
      return;
    }

    const fullAddress = AddressUtil.generateFullAddressForManualAddress(values);
    if (isEqual(fullAddress, values.fullAddress)) {
      return;
    }

    setFieldValue("fullAddress", fullAddress);
  }, [values, setFieldValue]);

  return (
    <FlexBox direction="column" spacing={3} data-testid="FormikAddressInput">
      <Guard
        condition={!values.isManualAddress}
        fallback={
          <StyledManualAddressInputFormControl
            fullWidth={true}
            hiddenLabel={true}
            variant={"outlined"}
            required={isRequired}
          >
            <InputLabel shrink={false}>{t(label)}</InputLabel>
          </StyledManualAddressInputFormControl>
        }
      >
        <FormControl
          fullWidth={true}
          hiddenLabel={true}
          variant={"outlined"}
          required={isRequired}
          error={Boolean(errors["fullAddress"])}
        >
          <InputLabel htmlFor={inputId} shrink={false}>
            {t(label)}
          </InputLabel>
          <Autocomplete
            data-testid="FormikAddressInputAutocomplete"
            id={inputId}
            freeSolo={false}
            openOnFocus={false}
            selectOnFocus={false}
            blurOnSelect={true}
            onChange={handleAddressSearchValueChange}
            onInputChange={handleAddressSearchInputChange}
            inputValue={addressSearchInputValue}
            open={isOptionListOpen}
            PaperComponent={StyledAutocompletePopupPaper}
            ListboxComponent={StyledAutocompleteListbox}
            renderInput={(params) => {
              return (
                <div ref={params.InputProps.ref}>
                  <OutlinedInput
                    {...params.inputProps}
                    fullWidth={true}
                    type="search"
                    autoComplete="off"
                    onBlur={(evt) => {
                      isAutocompleteInputFocusedRef.current = false;

                      const inputProps: any = params.inputProps;
                      if (inputProps.onBlur) {
                        inputProps.onBlur(evt);
                      }

                      handleAddressSearchInputBlur(evt);
                    }}
                    onFocus={(evt) => {
                      isAutocompleteInputFocusedRef.current = true;

                      const inputProps: any = params.inputProps;
                      if (inputProps.onFocus) {
                        inputProps.onFocus(evt);
                      }
                    }}
                    error={Boolean(errors["fullAddress"])}
                  />
                </div>
              );
            }}
            options={addressOptions}
            getOptionLabel={(option) => option.fullAddress}
            getOptionSelected={(option, value) => {
              return option.identifier === value.identifier;
            }}
          />

          {errors["fullAddress"] && (
            <FormHelperText error={true} data-testid={`InputError${capitalize("fullAddress")}`}>
              {errors["fullAddress"]}
            </FormHelperText>
          )}
        </FormControl>
      </Guard>

      <LabelledCheckbox
        data-testid="FormikAddressInputIsManualToggle"
        label={t(`Can't find the address? Enter it manually`)}
        fieldName={"isManualAddress"}
        selected={values["isManualAddress"]}
        onSelect={handleSelectIsManualAddress}
        onDeselect={handleDeselectIsManualAddress}
      />

      <Guard condition={values.isManualAddress}>
        <FlexBox direction="column" spacing={6}>
          <FormikTextInput label={t(`Address line 1`)} fieldName="address1" required={isRequired} />
          <FormikTextInput label={t(`Address line 2`)} fieldName="address2" />
          <FlexBox spacing={6} direction={screenWidthMatch.md ? "row" : "column"}>
            <FormikTextInput label={t(`City`)} fieldName="city" required={isRequired} />
            <FormikTextInput label={t(`State`)} fieldName="state" />
          </FlexBox>
          <FlexBox spacing={6} direction={screenWidthMatch.md ? "row" : "column"}>
            <FormikTextInput label={t(`Country`)} fieldName="country" required={isRequired} />
            <FormikTextInput label={t(`Zip code`)} fieldName="zipCode" required={isRequired} />
          </FlexBox>
        </FlexBox>
      </Guard>
    </FlexBox>
  );
};

const StyledAutocompletePopupPaper = styled(Paper)(
  ({ theme }) => css`
    box-shadow: ${theme.shadows[6]};
  `
);

const StyledAutocompleteListbox = styled.ul(
  ({ theme }) => css`
    & > li {
      border-left: 4px solid transparent;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    & > li:hover {
      border-left: 4px solid ${theme.palette.primary.main};
      background: ${theme.palette.objective.blue.light};
    }
  `
);

const StyledManualAddressInputFormControl = styled(FormControl)(
  ({ theme }) => css`
    & > label {
      padding-bottom: 0;
    }
  `
);

export default FormikAddressInput;
