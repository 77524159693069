import React, { useRef } from "react";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import {
  selectCurrentParticipant,
  selectIsParticipantGroupEnabled,
  selectIsParticipantLinkedToUser,
  selectPersonalInfoFormFields,
  setPersonalInfoFormFields,
} from "store/app-state/participant-buffer/participantBuffer";
import { IParticipantPersonalInfoFormData, ParticipantAccessLevel, rankAccessLevel } from "models/Participant.model";
import * as yup from "yup";
import { formValidationUtils } from "utils/formValidationUtils";
import FlexBox from "components/FlexBox/FlexBox";
import FormikTextInput from "components/FormikTextInput/FormikTextInput";
import FormikEmailInput from "components/FormikEmailInput/FormikEmailInput";
import { useTranslation } from "react-i18next";
import { selectMustInviteSelectedParticipantTypes } from "store/domain-data/participant-type/participantType";
import { selectEmailExistsOnOtherApplicationParticipant } from "store/domain-data/participant/participant";
import isEmpty from "lodash/isEmpty";
import { useStore } from "react-redux";
import Switch from "components/Switch/Switch";
import FormikContactLookupInput, {
  FieldSearchType,
} from "components/FormikContactLookupInput/FormikContactLookupInput";
import FormikAddressInput from "components/FormikAddressInput/FormikAddressInput";
import { selectEmailFoundOnExistingContact } from "store/domain-data/contact/contact";
import { IContactEntity } from "models/Contact.model";
import Guard from "components/Guard/Guard";
import FormikGroupInput from "../FormikGroupInput/FormikGroupInput";
import FormikChangeDetection from "components/FormikChangeDetection/FormikChangeDetection";

import styled, { css } from "styled-components/macro";
import { useScreenWidthMatch } from "hooks/useScreenWidthMatch";
import FormikContactNumberInput from "components/FormikContactNumberInput/FormikContactNumberInput";
import { DEFAULT_PHONE_COUNTRY_CODE, FULL_CONTACT_PHONE_ENABLED } from "constants/configs";

type Props = {
  isSaveContactSelected: boolean;
  onFormValidated: (invalid: boolean) => void;
  onChangeSaveContact: (enabled: boolean) => void;
};

const ParticipantPersonalInfoForm: React.FC<Props> = ({
  isSaveContactSelected,
  onFormValidated,
  onChangeSaveContact,
}) => {
  // Hooks
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const store = useStore();
  const formRef = useRef<FormikProps<IParticipantPersonalInfoFormData>>(null);

  const currentFormFields = useStoreSelector((state) => selectPersonalInfoFormFields(state));
  const currentParticipant = useStoreSelector((state) => selectCurrentParticipant(state));

  const isParticipantLinkedToUser = useStoreSelector((state) => selectIsParticipantLinkedToUser(state));
  const mustInviteSelectedParticipantsTypes = useStoreSelector((state) =>
    selectMustInviteSelectedParticipantTypes(state, { selectedParticipantTypes: currentParticipant.participantTypes })
  );
  const isParticipantGroupEnabled = useStoreSelector((state) => selectIsParticipantGroupEnabled(state));

  const screenWidthMatcher = useScreenWidthMatch();

  const [saveContactDisabled, setSaveContactDisabled] = React.useState(false);

  const isNewParticipant = React.useMemo(() => !Boolean(currentParticipant.id), [currentParticipant.id]);

  const isEmailRequired = React.useMemo(() => {
    const hasUserAccess =
      rankAccessLevel(currentParticipant.accessLevel) >= rankAccessLevel(ParticipantAccessLevel.ReadOnly);
    return hasUserAccess || !isEmpty(mustInviteSelectedParticipantsTypes);
  }, [mustInviteSelectedParticipantsTypes, currentParticipant]);

  const isPhoneNumberRequired = React.useMemo(() => {
    return currentParticipant.participantTypes.some((participantType) => {
      return ["OwnerContact", "Agent", "InvoicePayer"].includes(participantType.name);
    });
  }, [currentParticipant]);

  const isAddressRequired = React.useMemo(() => {
    return currentParticipant.participantTypes.some((participantType) => {
      return ["OwnerContact", "Agent", "InvoicePayer"].includes(participantType.name);
    });
  }, [currentParticipant]);

  const labelForSaveContact = React.useMemo(() => {
    if (saveContactDisabled) {
      return t(`Cannot save to contact library as email address already exists`);
    } else {
      return t(`Save this person to my contact library`);
    }
  }, [saveContactDisabled, t]);

  const participantFormSchema = React.useMemo(() => {
    const testForEmailRequired = (value: string | undefined) => {
      return !isEmailRequired || !!value;
    };

    const testForPhoneRequired = (value: string | undefined) => {
      return !isPhoneNumberRequired || !!value;
    };

    const testForEmailUnique = (value: string | undefined) => {
      const emailAlreadyExists = selectEmailExistsOnOtherApplicationParticipant(store.getState(), {
        applicationId: currentParticipant.applicationId,
        participantId: currentParticipant.id,
        participantEmail: value || "",
      });

      return !emailAlreadyExists || !value;
    };

    let schema = yup.object().shape({
      firstName: yup
        .string()
        .required(formValidationUtils.getErrorMessageForRequiredField())
        .matches(formValidationUtils.getRegexForTrimmedName(), formValidationUtils.getErrorMessageForName()),
      lastName: yup
        .string()
        .required(formValidationUtils.getErrorMessageForRequiredField())
        .matches(formValidationUtils.getRegexForTrimmedName(), formValidationUtils.getErrorMessageForName()),
      email: yup
        .string()
        .matches(formValidationUtils.getRegexForEmail(), formValidationUtils.getErrorMessageForEmail())
        .test("email-required", formValidationUtils.getErrorMessageForRequiredField(), testForEmailRequired)
        .test("email-unique", formValidationUtils.getErrorMessageForDuplicateEmail(), testForEmailUnique),
    });

    if (FULL_CONTACT_PHONE_ENABLED) {
      schema = schema.shape({
        phoneCountryCode: yup.string(),
        phone: yup.string().when("phoneCountryCode", (phoneCountryCode: string) => {
          return yup
            .string()
            .test("phone-required", formValidationUtils.getErrorMessageForRequiredField(), testForPhoneRequired)
            .matches(
              formValidationUtils.getRegexForContactNumber(phoneCountryCode),
              formValidationUtils.getErrorMessageForContactNumber()
            );
        }),
      });
    } else {
      schema = schema.shape({
        phone: yup
          .string()
          .test("phone-required", formValidationUtils.getErrorMessageForRequiredField(), testForPhoneRequired),
      });
    }

    if (isAddressRequired) {
      schema = schema.shape({
        address1: yup.string().required(formValidationUtils.getErrorMessageForRequiredField()),
        city: yup.string().required(formValidationUtils.getErrorMessageForRequiredField()),
        country: yup.string().required(formValidationUtils.getErrorMessageForRequiredField()),
        zipCode: yup.string().required(formValidationUtils.getErrorMessageForRequiredField()),
        fullAddress: yup.string().required(formValidationUtils.getErrorMessageForRequiredField()),
      });
    }

    return schema;
  }, [
    currentParticipant.applicationId,
    currentParticipant.id,
    isEmailRequired,
    isPhoneNumberRequired,
    store,
    isAddressRequired,
  ]);

  const handleContactLookupSelection = React.useCallback(
    (contact: IContactEntity) => {
      const newFormFields = {
        firstName: contact.firstName,
        lastName: contact.lastName,
        organisation: contact.organisation,
        email: contact.email,
        phoneCountryCode: contact.phoneCountryCode || DEFAULT_PHONE_COUNTRY_CODE,
        phone: contact.phone,
        address1: contact.address.address1,
        address2: contact.address.address2,
        city: contact.address.city,
        state: contact.address.state,
        country: contact.address.country,
        zipCode: contact.address.zipCode,
        isManualAddress: contact.address.isManualAddress,
        fullAddress: contact.address.fullAddress,
      };
      dispatch(setPersonalInfoFormFields(newFormFields));
    },
    [dispatch]
  );

  const handleSubmit = React.useCallback(
    async (
      formValues: IParticipantPersonalInfoFormData,
      formikHelpers: FormikHelpers<IParticipantPersonalInfoFormData>
    ) => {
      dispatch(setPersonalInfoFormFields(formValues));
    },
    [dispatch]
  );

  // Required to dynamically detect participant type unselections and revalidate with updated schema
  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [currentParticipant.participantTypes, currentParticipant.accessLevel]);

  React.useEffect(() => {
    // Only perform save to contact library checks for new participants
    if (isNewParticipant) {
      const emailAlreadyExists = selectEmailFoundOnExistingContact(store.getState(), currentParticipant.email);

      if (emailAlreadyExists) {
        onChangeSaveContact(false);
      }

      setSaveContactDisabled(emailAlreadyExists);
    }
  }, [store, currentParticipant, isNewParticipant, onChangeSaveContact]);

  if (!currentFormFields) {
    return null;
  }

  return (
    <StyledParticipantPersonalInfoForm data-testid="EditParticipantStepPersonalInfo">
      <Formik<IParticipantPersonalInfoFormData>
        initialValues={currentFormFields}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={participantFormSchema}
        validateOnChange={true}
        innerRef={formRef}
      >
        {() => (
          <Form>
            <StyledParticipantPersonalFormWrapper>
              <Guard condition={isNewParticipant}>
                <StyledNameInputWrapper>
                  <FormikContactLookupInput
                    label={t(`First name`)}
                    fieldName="firstName"
                    fieldToSearch={FieldSearchType.FIRST_NAME}
                    initialValue={currentFormFields.firstName}
                    required={true}
                    onLookupSelection={handleContactLookupSelection}
                  />
                  <FormikContactLookupInput
                    label={t(`Last name`)}
                    fieldName="lastName"
                    fieldToSearch={FieldSearchType.LAST_NAME}
                    initialValue={currentFormFields.lastName}
                    required={true}
                    onLookupSelection={handleContactLookupSelection}
                  />
                </StyledNameInputWrapper>
              </Guard>
              <Guard condition={!isNewParticipant}>
                <FlexBox spacing={8} direction={screenWidthMatcher.md ? "row" : "column"}>
                  <FormikTextInput label={t(`First name`)} fieldName="firstName" required={true} />
                  <FormikTextInput label={t(`Last name`)} fieldName="lastName" required={true} />
                </FlexBox>
              </Guard>
              <Guard condition={isParticipantGroupEnabled}>
                <FormikGroupInput
                  groupList={currentParticipant.groupList}
                  groupName={currentParticipant.groupName}
                  isVisible={!!currentParticipant.groupList || !!currentParticipant.groupName}
                />
              </Guard>
              <FlexBox spacing={8} direction={screenWidthMatcher.md ? "row" : "column"}>
                <FormikTextInput label={t(`Business name`)} fieldName="organisation" />
                {FULL_CONTACT_PHONE_ENABLED && (
                  <FormikContactNumberInput
                    label={t(`Contact number`)}
                    contactNumberFieldName="phone"
                    countryCodeFieldName={"phoneCountryCode"}
                    required={isPhoneNumberRequired}
                  />
                )}
                {!FULL_CONTACT_PHONE_ENABLED && (
                  <FormikTextInput label={t(`Contact number`)} fieldName="phone" required={isPhoneNumberRequired} />
                )}
              </FlexBox>
              <FormikEmailInput
                label={t(`Email address`)}
                fieldName="email"
                required={isEmailRequired}
                disabled={isParticipantLinkedToUser}
              />
              <FormikAddressInput
                initAddress={currentParticipant.address}
                label={"Postal address"}
                isRequired={isAddressRequired}
              />
              <Guard condition={!currentParticipant.id}>
                <Switch
                  data-testid="ParticipantSaveContactSwitch"
                  label={labelForSaveContact}
                  fieldName="saveContact"
                  checked={isSaveContactSelected}
                  onChange={onChangeSaveContact}
                  disabled={saveContactDisabled}
                />
              </Guard>
            </StyledParticipantPersonalFormWrapper>
            <FormikChangeDetection onFormValidated={onFormValidated} />
          </Form>
        )}
      </Formik>
    </StyledParticipantPersonalInfoForm>
  );
};

const StyledNameInputWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    ${theme.mixins.flexGap("24px")}
    ${theme.breakpoints.down("md")} {
      flex-direction: column;
      ${theme.mixins.flexGap("8px")};
    }
    & > * {
      flex-grow: 1;
    }
  `
);
const StyledParticipantPersonalInfoForm = styled.div(
  ({ theme }) => css`
    padding: 32px 48px;
  `
);

const StyledParticipantPersonalFormWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${theme.mixins.flexGap("24px")}
  `
);

export default ParticipantPersonalInfoForm;
