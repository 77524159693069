import { createAction, createAsyncThunk, createReducer, createSelector, unwrapResult } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import {
  InvitationStatus,
  IParticipantEntity,
  IParticipantPersonalInfoFormData,
  IParticipantTypeApplicationEntity,
  ParticipantAccessLevel,
} from "models/Participant.model";
import { fetchParticipant } from "store/domain-data/participant/participant";
import { selectMatchingApplicationParticipantTypes } from "store/domain-data/participant-type/participantType";
import { isEqual, omit, sortBy } from "lodash";
import { IQualificationEntity } from "models/Qualification.model";
import { selectAllQualificationTypeEntities } from "store/domain-data/qualification-type/qualificationType";
import { createDeepEqualSelector } from "store/utils";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import { DEFAULT_PHONE_COUNTRY_CODE } from "constants/configs";

// Types

export type IParticipantBufferEntity = Omit<IParticipantEntity, "participantTypes" | "qualifications"> & {
  participantTypes: IParticipantTypeApplicationEntity[];
  qualifications: Array<IQualificationEntity & { isValid?: boolean }>;
};

export type ParticipantBufferState = {
  originalParticipant: IParticipantBufferEntity;
  currentParticipant: IParticipantBufferEntity;
};

// Common

const EMPTY_PARTICIPANT_FIELDS_MINUS_APPLICATION_ID = {
  id: 0,
  firstName: "",
  lastName: "",
  organisation: "",
  groupList: "",
  groupName: "",
  email: "",
  phoneCountryCode: "",
  phone: "",
  address: {
    address1: "",
    address2: "",
    city: "",
    country: "",
    isManualAddress: false,
    state: "",
    zipCode: "",
    fullAddress: "",
  },
  participantTypes: [],
  accessLevel: ParticipantAccessLevel.None,
  loginUserId: 0,
  invitation: InvitationStatus.None,
  qualifications: [],
};

const EMPTY_PARTICIPANT_FIELDS = {
  ...EMPTY_PARTICIPANT_FIELDS_MINUS_APPLICATION_ID,
  applicationId: 0,
};

// Actions & Thunks

export const startEditParticipant = createAsyncThunk(
  "appState/participantBuffer/startEditParticipant",
  async ({ applicationId, participantId }: { applicationId: number; participantId?: number }, thunkAPI) => {
    let existingParticipant = null;

    if (participantId) {
      existingParticipant = await thunkAPI
        .dispatch(fetchParticipant({ applicationId, participantId }))
        .then(unwrapResult);

      const appParticipantTypes = selectMatchingApplicationParticipantTypes(
        thunkAPI.getState() as RootState,
        existingParticipant.participantTypes
      );

      // Convert returned participant's types to be the full detail application participant types. Useful for:
      // - change detection
      // - simplified participantBuffer selectors
      existingParticipant.participantTypes = appParticipantTypes;
    }
    return { applicationId, existingParticipant };
  }
);

export const setPersonalInfoFormFields = createAction<IParticipantPersonalInfoFormData>(
  "appState/participantBuffer/setPersonalInfoFormFields"
);

export const addQualification = createAction<IParticipantBufferEntity["qualifications"][0]>(
  "appState/participantBuffer/addQualification"
);

export const updateQualification = createAction<{
  index: number;
  data: IParticipantBufferEntity["qualifications"][0];
}>("appState/participantBuffer/updateQualification");

export const removeQualification = createAction<{ index: number }>("appState/participantBuffer/removeQualification");

export const selectParticipantType = createAction<IParticipantTypeApplicationEntity>(
  "appState/participantBuffer/selectParticipantType"
);

export const unselectParticipantType = createAction<IParticipantTypeApplicationEntity>(
  "appState/participantBuffer/unselectParticipantType"
);

export const setAccessLevel = createAction<ParticipantAccessLevel>("appState/participantBuffer/setAccessLevel");

// Internal Helper

const isParticipantTypeSelected = (state: ParticipantBufferState, participantTypeName: string) => {
  return (
    state.currentParticipant &&
    state.currentParticipant.participantTypes.some((selectedType) => selectedType.name === participantTypeName)
  );
};

// Reducer

export const initialParticipantBufferState: ParticipantBufferState = {
  originalParticipant: EMPTY_PARTICIPANT_FIELDS,
  currentParticipant: EMPTY_PARTICIPANT_FIELDS,
};

export const participantBufferReducer = createReducer(initialParticipantBufferState, (builder) => {
  builder
    .addCase(startEditParticipant.fulfilled, (state, action) => {
      const existingParticipant = action.payload.existingParticipant;

      if (existingParticipant) {
        const participantBuffer: IParticipantBufferEntity = {
          ...cloneDeep(existingParticipant),
          qualifications: cloneDeep(existingParticipant.qualifications).map((item) => {
            return {
              ...item,
              isValid: false,
            };
          }),
        };

        state.currentParticipant = participantBuffer;
        state.originalParticipant = participantBuffer;
      } else {
        const newParticipant = {
          ...EMPTY_PARTICIPANT_FIELDS_MINUS_APPLICATION_ID,
          applicationId: action.payload.applicationId,
        };

        state.currentParticipant = newParticipant;
        state.originalParticipant = newParticipant;
      }
    })
    .addCase(setPersonalInfoFormFields, (state, action) => {
      state.currentParticipant.firstName = action.payload.firstName;
      state.currentParticipant.lastName = action.payload.lastName;
      state.currentParticipant.groupList = action.payload.groupList || "";
      state.currentParticipant.groupName = action.payload.groupName || "";
      state.currentParticipant.organisation = action.payload.organisation;
      state.currentParticipant.email = action.payload.email;
      state.currentParticipant.phoneCountryCode = action.payload.phoneCountryCode;
      state.currentParticipant.phone = action.payload.phone;
      state.currentParticipant.address = {
        address1: action.payload.address1,
        address2: action.payload.address2,
        city: action.payload.city,
        state: action.payload.state,
        country: action.payload.country,
        zipCode: action.payload.zipCode,
        isManualAddress: action.payload.isManualAddress,
        fullAddress: action.payload.fullAddress,
      };
    })
    .addCase(addQualification, (state, action) => {
      state.currentParticipant.qualifications.push(action.payload);
    })
    .addCase(updateQualification, (state, action) => {
      const { index, data } = action.payload;
      state.currentParticipant.qualifications[index] = data;
    })
    .addCase(removeQualification, (state, action) => {
      const { index } = action.payload;
      state.currentParticipant.qualifications.splice(index, 1);
    })
    .addCase(selectParticipantType, (state, action) => {
      if (!isParticipantTypeSelected(state, action.payload.name)) {
        state.currentParticipant.participantTypes = [...state.currentParticipant.participantTypes, action.payload];
      }
    })
    .addCase(unselectParticipantType, (state, action) => {
      if (isParticipantTypeSelected(state, action.payload.name)) {
        state.currentParticipant.participantTypes = state.currentParticipant.participantTypes.filter(
          (type) => type.name !== action.payload.name
        );
      }
    })
    .addCase(setAccessLevel, (state, action) => {
      state.currentParticipant.accessLevel = action.payload;
    });
});

// Selectors

const selectParticipantBufferState = (state: RootState) => state.appState.participantBuffer;

export const selectCurrentParticipant = createSelector([selectParticipantBufferState], (state) => {
  return state.currentParticipant;
});

export const selectOriginalParticipant = createSelector([selectParticipantBufferState], (state) => {
  return state.originalParticipant;
});

export const selectPersonalInfoFormFields = createSelector([selectParticipantBufferState], (state) => {
  return {
    firstName: state.currentParticipant.firstName,
    lastName: state.currentParticipant.lastName,
    groupList: state.currentParticipant.groupList,
    groupName: state.currentParticipant.groupName,
    organisation: state.currentParticipant.organisation,
    email: state.currentParticipant.email,
    phoneCountryCode: state.currentParticipant.phoneCountryCode || DEFAULT_PHONE_COUNTRY_CODE,
    phone: state.currentParticipant.phone,
    ...state.currentParticipant.address,
  };
});

export const selectCurrentParticipantTypes = createSelector([selectParticipantBufferState], (state) => {
  return state.currentParticipant.participantTypes;
});

export const selectOriginalParticipantTypes = createSelector([selectParticipantBufferState], (state) => {
  return state.originalParticipant.participantTypes;
});

export const selectCurrentAccessLevel = createSelector([selectParticipantBufferState], (state) => {
  return state.currentParticipant.accessLevel;
});

export const selectIsParticipantLinkedToUser = createSelector([selectParticipantBufferState], (state) => {
  return (
    !!state.originalParticipant.loginUserId ||
    state.originalParticipant.invitation === InvitationStatus.Queued ||
    state.originalParticipant.invitation === InvitationStatus.Unregistered
  );
});

export const selectIsParticipantGroupEnabled = createSelector([selectCurrentParticipantTypes], (selectedTypes) =>
  selectedTypes.some((type) => type.enableGroups)
);

export const selectIsParticipantChanged = createSelector([selectParticipantBufferState], (state) => {
  const original = state.originalParticipant;
  const current = state.currentParticipant;

  const hasParticipantMinusTypesAndCertsChanged = !isEqual(
    omit(original, "participantTypes", "qualifications"),
    omit(current, "participantTypes", "qualifications")
  );

  const hasParticipantTypesChanged = !isEqual(
    sortBy(original.participantTypes, "id"),
    sortBy(current.participantTypes, "id")
  );

  const originalQualifications = original.qualifications.map((item) => omit(item, "isValid"));
  const currentQualifications = current.qualifications.map((item) => omit(item, "isValid"));

  const hasQualificationsChanged = !isEqual(
    sortBy(originalQualifications, ["qualificationName", "qualificationNumber", "otherQualificationType"]),
    sortBy(currentQualifications, ["qualificationName", "qualificationNumber", "otherQualificationType"])
  );

  const hasChanged = hasParticipantMinusTypesAndCertsChanged || hasParticipantTypesChanged || hasQualificationsChanged;
  return hasChanged;
});

export const selectCurrentQualifications = createSelector([selectParticipantBufferState], (state) => {
  return state.currentParticipant.qualifications;
});

export const selectCurrentQualification = createDeepEqualSelector(
  [(state: RootState) => selectCurrentQualifications(state), (state: RootState, index: number) => index],
  (currentQualifications, index) => {
    return currentQualifications[index];
  }
);

export const selectCurrentQualificationType = createDeepEqualSelector(
  [selectAllQualificationTypeEntities, selectCurrentQualification],
  (qualificationTypeEntities, currentQualification) => {
    const currentQualificationType = qualificationTypeEntities.find(
      (qualificationTypeEntity) => qualificationTypeEntity.name === currentQualification.qualificationName
    );
    if (!currentQualificationType) {
      return null;
    }

    return currentQualificationType;
  }
);

export const selectIsParticipantQualificationsValid = createDeepEqualSelector(
  [(state: RootState) => selectCurrentQualifications(state)],
  (currentQualifications) => {
    const isValid = isEmpty(currentQualifications) || currentQualifications.every((item) => item.isValid);
    return isValid;
  }
);
