import { IApplicationFilterService } from "services/application-filter/ApplicationFilterService.type";
import {
  ApplicationFilterGroup,
  IApplicationFilters,
  IApplicationFilterItem,
} from "components/ApplicationFilter/ApplicationFilter.types";
import { Cradle } from "services/serviceContainer.types";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import toString from "lodash/toString";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";

export class ApplicationFilterService implements IApplicationFilterService {
  private readonly apiClient: Cradle["apiClient"];
  private readonly i18n: Cradle["i18n"];

  constructor(args: Pick<Cradle, "apiClient" | "i18n">) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }
  public async fetchApplicationFilters(): Promise<IApplicationFilters> {
    const response = await this.apiClient.protectedApi.get(`/user/applicationsV2/filters`);
    return this.parseApplicationFilters(response.data);
  }

  private parseApplicationFilters(json: any): IApplicationFilters {
    const formFiltersJSON = get(json, "forms");
    const authorityFiltersJSON = get(json, "authorities");
    const statusFiltersJSON = get(json, "statuses");
    const complexityFiltersJSON = get(json, "complexities");
    const outstandingActionFiltersJSON = get(json, "outstandingActions");

    const isJSONInvalid = [formFiltersJSON, authorityFiltersJSON, statusFiltersJSON, complexityFiltersJSON].some(
      (arr) => !Array.isArray(arr)
    );

    if (isJSONInvalid) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch application filters`));
    }

    const getSortedFilters = (items: IApplicationFilterItem[]) => {
      return sortBy(items, (item) => item.name);
    };

    const formFilters = formFiltersJSON.map((item: any) => {
      return {
        id: toString(get(item, "id")),
        name: toString(get(item, "name")),
        group: ApplicationFilterGroup.Form,
      };
    });
    const sortedFormFilters = getSortedFilters(formFilters);

    const authorityFilters = authorityFiltersJSON.map((item: any) => {
      return {
        id: toString(get(item, "id")),
        name: toString(get(item, "name")),
        group: ApplicationFilterGroup.BCA,
      };
    });
    const sortedAuthorityFilters = getSortedFilters(authorityFilters);

    const statusFilters = statusFiltersJSON.map((item: any) => {
      return {
        id: toString(get(item, "id")),
        name: toString(get(item, "name")),
        group: ApplicationFilterGroup.Status,
      };
    });
    const sortedStatusFilters = getSortedFilters(statusFilters);

    const complexityFilters = complexityFiltersJSON.map((item: any) => {
      return {
        id: toString(get(item, "id")),
        name: toString(get(item, "name")),
        group: ApplicationFilterGroup.Complexity,
      };
    });
    const sortedComplexityFilters = getSortedFilters(complexityFilters);

    const outstandingActionFilters = outstandingActionFiltersJSON.map((item: any) => {
      return {
        id: toString(get(item, "id")),
        name: toString(get(item, "name")),
        group: ApplicationFilterGroup.OutstandingAction,
      };
    });
    const sortedOutstandingActionFilters = getSortedFilters(outstandingActionFilters);

    return {
      forms: sortedFormFilters,
      authorities: sortedAuthorityFilters,
      statuses: sortedStatusFilters,
      complexities: sortedComplexityFilters,
      outstandingActions: sortedOutstandingActionFilters,
    };
  }
}
