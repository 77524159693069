import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserSession,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { ICognitoService, LoginArgs, ResetPasswordArgs } from "./CognitoService.types";
import { Cradle } from "services/serviceContainer.types";
import { ICognitoUser } from "models/CognitoUser.model";
import { AWS_USER_POOLS_ID, AWS_USER_POOLS_WEB_CLIENT_ID } from "constants/configs";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";
import trim from "lodash/trim";

export class CognitoService implements ICognitoService {
  private readonly logger: Cradle["logger"];
  private readonly i18n: Cradle["i18n"];
  private readonly userPool: CognitoUserPool;

  /**
   * Dependencies will be injected
   * @param args
   */
  constructor(args: Pick<Cradle, "logger" | "i18n">) {
    this.logger = args.logger;
    this.i18n = args.i18n;
    this.userPool = new CognitoUserPool({
      UserPoolId: AWS_USER_POOLS_ID,
      ClientId: AWS_USER_POOLS_WEB_CLIENT_ID,
    });
  }

  public login({ username, password }: LoginArgs): Promise<ICognitoUser> {
    return new Promise((resolve, reject) => {
      const authDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: this.userPool,
      });
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (userSession) => {
          this.parseCognitoUser(cognitoUser)
            .then((user) => resolve(user))
            .catch((e) => reject(e));
        },
        onFailure: (error) => {
          this.logger.error("error signing in: ", error);

          let code = ServiceErrorCode.ServerError;
          if (error && error.code === "UserNotConfirmedException") {
            code = ServiceErrorCode.UserNotActive;
          }
          const serviceError = new ServiceError(code, this.getLocalizedErrorMessage(error));
          reject(serviceError);
        },
        newPasswordRequired: (userAttributes) => {
          const _userAttributes = { ...userAttributes };
          delete _userAttributes.email_verified;
          cognitoUser.completeNewPasswordChallenge(password, _userAttributes, {
            onSuccess: (userSession) => {
              this.parseCognitoUser(cognitoUser)
                .then((user) => resolve(user))
                .catch((e) => reject(e));
            },
            onFailure: (error) => {
              this.logger.error("error after password required: ", error);
              const serviceError = new ServiceError(ServiceErrorCode.ServerError, this.getLocalizedErrorMessage(error));
              reject(serviceError);
            },
          });
        },
      });
    });
  }

  public loginWithCachedState(): Promise<ICognitoUser> {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.userPool.getCurrentUser();
      if (!cognitoUser) {
        this.logger.error("Cognito user not found for login with cached state");
        const serviceError = new ServiceError(
          ServiceErrorCode.ServerError,
          "Cognito user not found for login with cached state"
        );
        reject(serviceError);
        return;
      }
      cognitoUser.getSession((error: any, session: CognitoUserSession) => {
        if (error) {
          this.logger.error("error getting session: ", error);
          const errorMessage = new ServiceError(ServiceErrorCode.ServerError, this.getLocalizedErrorMessage(error));
          reject(errorMessage);
          return;
        }
        this.parseCognitoUser(cognitoUser)
          .then((user) => resolve(user))
          .catch((e) => reject(e));
      });
    });
  }

  /**
   * Logout user
   *  This service call will always success
   *  If there is any error, it will be logged and fail silently
   */
  public logout(): Promise<void> {
    try {
      this.userPool.getCurrentUser()?.signOut();
    } catch (error) {
      // Fail silently
      this.logger.error("error signing out: ", error);
    }

    return Promise.resolve();
  }

  /**
   * Get JWT token (id token)
   */
  public async getIdToken(): Promise<string> {
    try {
      const session = await this.getUserSession();
      return session.getIdToken().getJwtToken();
    } catch (error) {
      this.logger.error("error getting id token: ", error);
      throw new ServiceError(ServiceErrorCode.ServerError, this.getLocalizedErrorMessage(error));
    }
  }

  /**
   * Get JWT token (access token)
   */
  public async getAccessToken(): Promise<string> {
    try {
      const session = await this.getUserSession();
      return session.getAccessToken().getJwtToken();
    } catch (error) {
      this.logger.error("error getting access token: ", error);
      throw new ServiceError(ServiceErrorCode.ServerError, this.getLocalizedErrorMessage(error));
    }
  }

  /**
   * Request new password code
   */
  public forgotPassword(username: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: username,
        Pool: this.userPool,
      };
      const cognitoUser = new CognitoUser(userData);
      if (!cognitoUser) {
        this.logger.error("Cognito user not found");
        const serviceError = new ServiceError(ServiceErrorCode.ServerError, "Cognito user not found");
        reject(serviceError);
        return;
      }
      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (error) => {
          this.logger.error("forgot password has failed: ", error);
          const serviceError = new ServiceError(ServiceErrorCode.ServerError, this.getLocalizedErrorMessage(error));
          reject(serviceError);
        },
      });
    });
  }
  /**
   * Reset password
   */
  public resetPassword({ email, code, password }: ResetPasswordArgs): Promise<void> {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: email,
        Pool: this.userPool,
      };
      const cognitoUser = new CognitoUser(userData);
      if (!cognitoUser) {
        this.logger.error("Cognito user not found");
        const serviceError = new ServiceError(ServiceErrorCode.ServerError, "error getting user session");
        reject(serviceError);
        return;
      }
      cognitoUser.confirmPassword(code, password, {
        onSuccess: () => {
          resolve();
        },
        onFailure: (error) => {
          this.logger.error("reset password has failed: ", error);
          const serviceError = new ServiceError(ServiceErrorCode.ServerError, this.getLocalizedErrorMessage(error));
          reject(serviceError);
        },
      });
    });
  }

  public async forceTokenRefresh(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const cognitoUser = this.userPool.getCurrentUser();
      if (!cognitoUser) {
        reject(new ServiceError(ServiceErrorCode.ClientError, this.i18n.t("Cognito user not found")));
        return;
      }

      const session = await this.getUserSession();
      const refreshToken = session.getRefreshToken();
      cognitoUser.refreshSession(refreshToken, (error: any) => {
        if (error) {
          reject(new ServiceError(ServiceErrorCode.ServerError, this.getLocalizedErrorMessage(error)));
          return;
        }
        resolve();
      });
    });
  }

  private getUserAttributes(user: CognitoUser): Promise<CognitoUserAttribute[]> {
    return new Promise((resolve, reject) => {
      user.getUserAttributes((error, attributes) => {
        if (error) {
          this.logger.error("error getting user attributes: ", error);
          const serviceError = new ServiceError(ServiceErrorCode.ServerError, this.getLocalizedErrorMessage(error));
          reject(serviceError);
          return;
        }
        if (attributes === undefined) {
          resolve([]);
          return;
        }
        resolve(attributes);
      });
    });
  }

  private getUserSession(): Promise<CognitoUserSession> {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.userPool.getCurrentUser();
      if (!cognitoUser) {
        this.logger.error("Cognito user not found in getting user session");
        const serviceError = new ServiceError(
          ServiceErrorCode.ServerError,
          "Cognito user not found in getting user session"
        );
        reject(serviceError);
        return;
      }
      cognitoUser.getSession((error: any, session: CognitoUserSession) => {
        if (error) {
          this.logger.error("error getting user session: ", error);
          const serviceError = new ServiceError(ServiceErrorCode.ServerError, this.getLocalizedErrorMessage(error));
          reject(serviceError);
          return;
        }
        resolve(session);
      });
    });
  }

  private async parseCognitoUser(user: CognitoUser): Promise<ICognitoUser> {
    try {
      const userAttributes = await this.getUserAttributes(user);
      const emailAttr = userAttributes.find((attribute) => attribute.getName() === "email");
      const emailVerifiedAttr = userAttributes.find((attribute) => attribute.getName() === "email_verified");
      return {
        username: user.getUsername() || "",
        email: emailAttr?.getValue() || "",
        emailVerified: Boolean(emailVerifiedAttr?.getValue()) || false,
      };
    } catch (error) {
      this.logger.error("error building user");
      throw new ServiceError(ServiceErrorCode.ServerError, this.getLocalizedErrorMessage(error));
    }
  }

  /**
   * The translation keys and values of this service are controlled by Backend (and Cognito SDK)
   * @param error
   * @private
   */
  private getLocalizedErrorMessage(error: Error): string {
    const errorMessage = trim(error?.message || "", ".");
    const localizedErrorMessage = this.i18n.t(errorMessage);
    return localizedErrorMessage;
  }
}
