import React from "react";
import { useTranslation } from "react-i18next";
import FlexBox from "components/FlexBox/FlexBox";
import { Form, Formik } from "formik";
import { IQualificationEntity, QualificationUtil } from "models/Qualification.model";
import { useStoreSelector } from "store/hooks";
import ParticipantQualificationTypeSelect from "components/ParticipantEditDialog/ParticipantQualificationTypeSelect";
import FormikTextInput from "components/FormikTextInput/FormikTextInput";
import {
  selectCurrentQualification,
  selectCurrentQualificationType,
} from "store/app-state/participant-buffer/participantBuffer";
import styled, { css } from "styled-components/macro";
import Guard from "components/Guard/Guard";
import ParticipantQualificationLicenseClasses from "components/ParticipantEditDialog/ParticipantQualificationLicenseClasses";
import ParticipantQualificationFormDataSync from "components/ParticipantEditDialog/ParticipantQualificationFormDataSync";
import { RemoveQualificationButton } from "components/ParticipantEditDialog/RemoveQualificationButton";
import { getQualificationFormSchema } from "components/ParticipantEditDialog/functions/getQualificationFormSchema";
import { QualificationFormField } from "components/ParticipantEditDialog/models/QualificationFormField";
import ParticipantQualificationLicenseIdInput from "./ParticipantQualificationLicenseIdInput";

type Props = {
  index: number;
};

const ParticipantQualificationForm: React.FC<Props> = ({ index }) => {
  const { t } = useTranslation();

  const currentQualification = useStoreSelector((state) => selectCurrentQualification(state, index));
  const currentQualificationType = useStoreSelector((state) => selectCurrentQualificationType(state, index));

  const [initialValues] = React.useState(currentQualification);

  const qualificationFormSchema = React.useMemo(() => getQualificationFormSchema(currentQualificationType), [
    currentQualificationType,
  ]);

  const isOtherQualification = QualificationUtil.isOtherQualification(currentQualification.qualificationName);

  return (
    <Formik<IQualificationEntity>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={qualificationFormSchema}
      validateOnChange={true}
    >
      {() => (
        <React.Fragment>
          <ParticipantQualificationFormDataSync index={index} />

          <StyledForm>
            <StyledRemoveButtonContainer>
              <RemoveQualificationButton index={index} />
            </StyledRemoveButtonContainer>

            <FlexBox direction="column" spacing={8}>
              <ParticipantQualificationTypeSelect />

              <Guard condition={!isOtherQualification}>
                <ParticipantQualificationLicenseClasses />
              </Guard>

              <Guard condition={isOtherQualification}>
                <FormikTextInput
                  label={t(`Other qualification type`)}
                  fieldName={QualificationFormField.OtherQualificationType}
                  required={true}
                />
              </Guard>

              <ParticipantQualificationLicenseIdInput />
            </FlexBox>
          </StyledForm>
        </React.Fragment>
      )}
    </Formik>
  );
};

const handleSubmit = () => {
  // No form submission should happen
};

const StyledForm = styled(Form)(
  () => css`
    position: relative;
    padding: 16px 0;
  `
);

const StyledRemoveButtonContainer = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;
  `
);

export default ParticipantQualificationForm;
