import React from "react";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { useFormikContext } from "formik";
import { IQualificationEntity } from "models/Qualification.model";
import { selectCurrentQualification, updateQualification } from "store/app-state/participant-buffer/participantBuffer";
import sortBy from "lodash/sortBy";
import { useStore } from "react-redux";
import { RootState } from "store/types";
import isEmpty from "lodash/isEmpty";
import { serviceContainer } from "services/serviceContainer";
import isEqual from "lodash/isEqual";

type Props = {
  index: number;
};

const ParticipantQualificationFormDataSync: React.FC<Props> = ({ index }) => {
  const store = useStore<RootState>();
  const dispatch = useStoreDispatch();
  const { values, validateForm, setErrors } = useFormikContext<IQualificationEntity>();

  const currentQualification = useStoreSelector((state) => selectCurrentQualification(state, index));

  React.useEffect(() => {
    const logger = serviceContainer.cradle.logger;

    const syncData = async () => {
      const formErrors = await validateForm(values);
      const isValid = isEmpty(formErrors);

      if (
        currentQualification.qualificationName === values.qualificationName &&
        currentQualification.qualificationNumber === values.qualificationNumber &&
        currentQualification.otherQualificationType === values.otherQualificationType &&
        isEqualLicensingClasses(currentQualification.licensingClasses, values.licensingClasses) &&
        currentQualification.isValid === isValid
      ) {
        return;
      }

      const newData = {
        ...values,
        isValid,
      };

      logger.debug("ParticipantQualificationFormDataSync::syncData", { index, newData, oldData: currentQualification });

      dispatch(
        updateQualification({
          index,
          data: newData,
        })
      );
    };

    syncData().catch(logger.error);
  }, [dispatch, values, index, validateForm, currentQualification, setErrors, store]);

  return null;
};

const isEqualLicensingClasses = (prev: string[], current: string[]) => {
  const prevSorted = sortBy(prev);
  const currentSorted = sortBy(current);
  return isEqual(prevSorted, currentSorted);
};

export default ParticipantQualificationFormDataSync;
