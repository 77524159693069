import React from "react";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import styled, { css } from "styled-components/macro";
import { Chip } from "@material-ui/core";
import FlexBox from "components/FlexBox/FlexBox";
import isEmpty from "lodash/isEmpty";

type IOption = {
  name: string;
  displayName: string;
};

type Props = {
  availableOptions: IOption[];
  selectedOptions: IOption[];
  onChangeSelectedOptions: (options: IOption[]) => void;
  renderInput: (inputParams: any) => React.ReactNode;
};

const filter = createFilterOptions<IOption>();

const OptionsManagementCombo: React.FC<Props> = ({
  availableOptions,
  selectedOptions,
  onChangeSelectedOptions,
  renderInput,
}) => {
  const [inputValue, setInputValue] = React.useState("");
  const [value, setValue] = React.useState<IOption | null>(null);

  const handleRemoveSelectOption = React.useCallback(
    (option: IOption) => {
      const newSelectedOptions = selectedOptions.filter((item) => item.name !== option.name);
      onChangeSelectedOptions(newSelectedOptions);
    },
    [onChangeSelectedOptions, selectedOptions]
  );

  const handleAddSelectOption = React.useCallback(
    (option: IOption) => {
      let newSelectedOptions = [...selectedOptions];

      if (!newSelectedOptions.some((item) => item.name === option.name)) {
        newSelectedOptions.push(option);
      }

      onChangeSelectedOptions(newSelectedOptions);
    },
    [onChangeSelectedOptions, selectedOptions]
  );

  const handleInputChange = React.useCallback(
    (event: object, fieldValue: string, reason: "input" | "reset" | "clear") => {
      if (reason !== "input") {
        setInputValue("");
        return;
      }
      setInputValue(fieldValue.trimStart());
    },
    []
  );

  const handleAutocompleteFieldChange = React.useCallback(
    (
      event: React.ChangeEvent<any>,
      option: string | IOption | null,
      reason: "create-option" | "select-option" | "remove-option" | "blur" | "clear"
    ) => {
      // We don't handle these cases
      if (!option || typeof option !== "object" || reason !== "select-option") {
        return;
      }

      // Set value back to null to clear input
      setValue(null);
      setInputValue("");

      handleAddSelectOption(option);
    },
    [handleAddSelectOption]
  );

  const handleFilterOptions = React.useCallback(
    (options: IOption[], params: any) => {
      const filtered = filter(options, params).filter(
        (option) => !selectedOptions.some((item) => item.name === option.name)
      );

      return filtered;
    },
    [selectedOptions]
  );

  const handleGetOptionLabel = React.useCallback((option: IOption) => {
    return option.displayName;
  }, []);

  const handleRenderOption = React.useCallback((option: IOption) => {
    return option.displayName;
  }, []);

  React.useEffect(() => {
    onChangeSelectedOptions(selectedOptions);
  }, [onChangeSelectedOptions, selectedOptions]);

  return (
    <FlexBox direction="column" spacing={2}>
      <StyledAutocomplete<any>
        value={value}
        onChange={handleAutocompleteFieldChange}
        filterOptions={handleFilterOptions}
        options={availableOptions}
        getOptionLabel={handleGetOptionLabel}
        renderOption={handleRenderOption}
        renderInput={renderInput}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        autoComplete
        autoHighlight
        autoSelect
      />

      {!isEmpty(selectedOptions) && (
        <StyledSelectedOptionsContainer>
          {selectedOptions.map((option) => {
            return (
              <StyledChip
                key={option.name}
                label={option.displayName}
                onDelete={() => handleRemoveSelectOption(option)}
              />
            );
          })}
        </StyledSelectedOptionsContainer>
      )}
    </FlexBox>
  );
};

const StyledAutocomplete = styled(Autocomplete)(
  ({ theme }) => css`
    background: white;

    .MuiAutocomplete-input {
      padding: 0 !important;
    }
  `
);

const StyledChip = styled(Chip)(
  ({ theme }) => css`
    line-height: 140%;
  `
);

const StyledSelectedOptionsContainer = styled(FlexBox)(
  ({ theme }) => css`
    padding: 0 12px 12px 12px;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    ${theme.mixins.flexGap("8px")}
  `
);

export default OptionsManagementCombo;
