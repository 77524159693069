import { Cradle } from "services/serviceContainer.types";
import get from "lodash/get";
import { IContactEntity, IContactPersonalInfoFormValues } from "models/Contact.model";
import toString from "lodash/toString";
import toInteger from "lodash/toInteger";
import { IContactService } from "services/contact/ContactService.types";
import { IAddressEntity } from "models/Address.model";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";

export class ContactService implements IContactService {
  private readonly apiClient: Cradle["apiClient"];
  private readonly i18n: Cradle["i18n"];

  constructor(args: { apiClient: Cradle["apiClient"]; i18n: Cradle["i18n"] }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }

  public async fetchContactsForCurrentUserOrganisation() {
    const response = await this.apiClient.protectedApi.get(`/user/organisation/contacts`);
    const jsonArr = get(response.data, "contacts");
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch contacts`));
    }

    const contacts: IContactEntity[] = jsonArr.map((json) => this.parseContact(json));
    return contacts;
  }

  public async fetchOrganisationContactById(id: number): Promise<IContactEntity> {
    const response = await this.apiClient.protectedApi.get(`/user/organisation/contacts/${id}`);
    const json = response.data;
    const contact = this.parseContact(json);
    return contact;
  }

  public async deleteOrganisationContactById(id: number): Promise<void> {
    await this.apiClient.protectedApi.delete(`/user/organisation/contacts/${id}`);
  }

  public async createContact(formValues: Partial<IContactPersonalInfoFormValues>): Promise<IContactEntity> {
    const payload: Partial<IContactEntity> = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      organisation: formValues.organisation,
      phoneCountryCode: formValues.phoneCountryCode,
      phone: formValues.phone,
      email: formValues.email,
      address: {
        address1: formValues.address1 || "",
        address2: formValues.address2 || "",
        city: formValues.city || "",
        state: formValues.state || "",
        country: formValues.country || "",
        zipCode: formValues.zipCode || "",
        fullAddress: formValues.fullAddress || "",
        isManualAddress: formValues.isManualAddress || false,
      },
    };
    const response = await this.apiClient.protectedApi.post(`/user/organisation/contacts`, payload);
    const json = response.data;
    const contact = this.parseContact(json);
    return contact;
  }

  public async updateContact(
    contactId: number,
    formValues: Partial<IContactPersonalInfoFormValues>
  ): Promise<IContactEntity> {
    const payload: Partial<IContactEntity> = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      organisation: formValues.organisation,
      phoneCountryCode: formValues.phoneCountryCode,
      phone: formValues.phone,
      email: formValues.email,
      address: {
        address1: formValues.address1 || "",
        address2: formValues.address2 || "",
        city: formValues.city || "",
        state: formValues.state || "",
        country: formValues.country || "",
        zipCode: formValues.zipCode || "",
        fullAddress: formValues.fullAddress || "",
        isManualAddress: formValues.isManualAddress || false,
      },
    };

    const response = await this.apiClient.protectedApi.patch(`/user/organisation/contacts/${contactId}`, payload);
    const json = response.data;
    const contact = this.parseContact(json);
    return contact;
  }

  private parseContact(json: any): IContactEntity {
    const address: IAddressEntity = {
      address1: toString(get(json, "address.address1")),
      address2: toString(get(json, "address.address2")),
      city: toString(get(json, "address.city")),
      state: toString(get(json, "address.state")),
      country: toString(get(json, "address.country")),
      zipCode: toString(get(json, "address.zipCode")),
      fullAddress: toString(get(json, "address.fullAddress")),
      isManualAddress: Boolean(get(json, "address.isManualAddress")),
    };

    return {
      id: toInteger(get(json, "id")),
      firstName: toString(get(json, "firstName")),
      lastName: toString(get(json, "lastName")),
      organisation: toString(get(json, "organisation")),
      phoneCountryCode: toString(get(json, "phoneCountryCode")),
      phone: toString(get(json, "phone")),
      email: toString(get(json, "email")),
      address: address,
      lastUsed: toInteger(get(json, "lastUsed")),
    };
  }
}
