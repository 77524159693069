export enum QualificationType {
  LicensedBuildingPractitioner = "Licenced Building Practitioner",
}

export type IQualificationTypeLicenceClass = {
  id: number;
  name: string;
  displayName: string;
  displayOrder: number;
};

export type IQualificationTypeEntity = {
  id: number;
  name: string;
  displayName: string;
  displayOrder: number;
  regex: string;
  validationErrorMessage: string;
  classes: IQualificationTypeLicenceClass[];
};

export type IQualificationEntity = {
  qualificationName: string;
  licensingClasses: string[];
  otherQualificationType: string;
  qualificationNumber: string;
};

export const QualificationUtil = {
  isOtherQualification: (qualificationName: string) => {
    return qualificationName === "Other";
  },
};
