import React from "react";
import ParticipantTypeTag from "components/ParticipantTypeTag/ParticipantTypeTag";
import { useStoreSelector } from "store/hooks";
import { selectParticipantEntityById } from "store/domain-data/participant/participant";
import { selectParticipantTypeEntityById } from "store/domain-data/participant-type/participantType";
import ParticipantCannotRemoveInformationDialog from "components/ParticipantCannotRemoveInformationDialog/ParticipantCannotRemoveInformationDialog";
import { useDialog } from "hooks/useDialog";
import { selectApplicationEntityById } from "store/domain-data/application/application";
import { ApplicationStatus } from "models/ApplicationStatus.model";

type Props = {
  participantId: number;
  participantTypeId: number;
  readOnly?: boolean;
};

const ParticipantCardParticipantTypeTag: React.FC<Props> = ({ participantId, participantTypeId, readOnly = false }) => {
  const {
    isDialogOpen: isLockInfoDialogOpen,
    closeDialog: closeLockInfoDialog,
    openDialog: openLockInfoDialog,
  } = useDialog();

  const participant = useStoreSelector((state) => selectParticipantEntityById(state, participantId));
  const participantType = useStoreSelector((state) => selectParticipantTypeEntityById(state, participantTypeId));
  const applicationId = participant?.applicationId || 0;
  const application = useStoreSelector((state) => selectApplicationEntityById(state, applicationId));

  const isLocked = React.useMemo(() => {
    if (!participant || !participantType) {
      return false;
    }

    // Mandatory roles are locked
    if (participantType.mandatory) {
      return true;
    }

    // Required roles are locked after submission
    if (participantType.required && application?.status !== ApplicationStatus.Draft) {
      return true;
    }

    // Otherwise not locked
    return false;
  }, [application?.status, participant, participantType]);

  // The only function of clicking tag is to show the lock info dialog
  const isClickable = React.useMemo(() => {
    if (readOnly) {
      return false;
    }

    return isLocked;
  }, [isLocked, readOnly]);
  const handleClickTag = React.useCallback(() => {
    if (!isClickable) {
      return;
    }

    openLockInfoDialog();
  }, [isClickable, openLockInfoDialog]);

  if (!participant || !participantType) {
    return null;
  }

  return (
    <React.Fragment>
      <ParticipantTypeTag
        displayName={participantType.displayName}
        isClickable={isClickable}
        isSelected={true}
        isLocked={isLocked}
        onClick={handleClickTag}
        data-participant-type-name={participantType.name}
      />

      <ParticipantCannotRemoveInformationDialog
        participantTypeDisplayNames={participantType.displayName}
        isOpen={isLockInfoDialogOpen}
        onClose={closeLockInfoDialog}
      />
    </React.Fragment>
  );
};

export default ParticipantCardParticipantTypeTag;
