import React from "react";
import FlexBox from "components/FlexBox/FlexBox";
import { useStoreSelector } from "store/hooks";
import styled, { css } from "styled-components/macro";
import { getParticipantInitial, getParticipantName } from "models/Participant.model";
import { selectCurrentParticipant } from "store/app-state/participant-buffer/participantBuffer";
import UserAvatar from "components/UserAvatar/UserAvatar";

const ParticipantHeadlineSimple: React.FC = () => {
  const currentParticipant = useStoreSelector((state) => selectCurrentParticipant(state));

  const participantInitials = React.useMemo(() => getParticipantInitial(currentParticipant), [currentParticipant]);
  const participantName = React.useMemo(() => getParticipantName(currentParticipant), [currentParticipant]);

  if (!participantInitials) {
    return null;
  }

  return (
    <StyledCardContainer data-testid={"ParticipantHeadlineSimple"}>
      <FlexBox alignSelf={"flex-start"}>
        <UserAvatar initials={participantInitials} rectSize={44} fontSize={18} />
      </FlexBox>
      <FlexBox flexGrow={1} spacing={3} direction={"column"} maxWidth={"95%"}>
        <StyledParticipantName>{participantName}</StyledParticipantName>
        <StyledParticipantSubheader>{currentParticipant.email}</StyledParticipantSubheader>
      </FlexBox>
    </StyledCardContainer>
  );
};

export default ParticipantHeadlineSimple;

const StyledCardContainer = styled.div(
  ({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    ${theme.mixins.flexGap("16px")}
    padding: 16px 0px;
  `
);

const StyledParticipantName = styled.span(
  ({ theme }) => css`
    font-weight: 600;
    font-size: 16px;
    color: ${theme.palette.text.primary};
  `
);

const StyledParticipantSubheader = styled.span(
  ({ theme }) => css`
    font-weight: 600;
    font-size: 14px;
    color: ${theme.palette.text.secondary};
  `
);
