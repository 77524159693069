import { IQualificationTypeService } from "./QualificationTypeService.types";
import { IQualificationTypeEntity, IQualificationTypeLicenceClass } from "models/Qualification.model";
import { Cradle } from "services/serviceContainer.types";
import get from "lodash/get";
import toInteger from "lodash/toInteger";
import toString from "lodash/toString";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";

export class QualificationTypeService implements IQualificationTypeService {
  private readonly i18n: Cradle["i18n"];
  private readonly apiClient: Cradle["apiClient"];

  /**
   * Dependencies will be injected
   * @param args
   */
  constructor(args: Pick<Cradle, "i18n" | "apiClient">) {
    this.i18n = args.i18n;
    this.apiClient = args.apiClient;
  }

  public async fetchQualificationTypesByApplicationId(applicationId: number): Promise<IQualificationTypeEntity[]> {
    const response = await this.apiClient.protectedApi.get(`/user/applications/${applicationId}/qualification-types`);
    const json = get(response, "data.qualificationTypes");
    if (!Array.isArray(json)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t("Fail to fetch qualification types"));
    }

    const qualificationTypes: IQualificationTypeEntity[] = [];
    for (const item of json) {
      const qualificationType = this.parseQualificationTypeFromJSON(item);
      qualificationTypes.push(qualificationType);
    }

    return qualificationTypes;
  }

  public parseQualificationTypeFromJSON(json: any): IQualificationTypeEntity {
    const licensingClassesJSON = get(json, "classes");
    let licensingClasses: IQualificationTypeLicenceClass[] = [];
    if (licensingClassesJSON && Array.isArray(licensingClassesJSON)) {
      licensingClasses = licensingClassesJSON.map((item: any) => {
        return this.parseLicenceClassFromJSON(item);
      });
    }

    const qualificationType: IQualificationTypeEntity = {
      id: toInteger(get(json, "id")),
      name: toString(get(json, "name")),
      displayName: toString(get(json, "displayName")),
      displayOrder: toInteger(get(json, "displayOrder")),
      regex: toString(get(json, "regex")),
      validationErrorMessage: toString(get(json, "validationErrorMessage")),
      classes: licensingClasses,
    };

    return qualificationType;
  }

  public parseLicenceClassFromJSON(json: any): IQualificationTypeLicenceClass {
    const licenceClass: IQualificationTypeLicenceClass = {
      id: toInteger(get(json, "id")),
      name: toString(get(json, "name")),
      displayName: toString(get(json, "displayName")),
      displayOrder: toInteger(get(json, "displayOrder")),
    };
    return licenceClass;
  }
}
