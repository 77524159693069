import React from "react";
import { useTranslation } from "react-i18next";
import { Select } from "@material-ui/core";
import FlexBox from "components/FlexBox/FlexBox";
import styled, { css } from "styled-components/macro";
import SelectOption from "components/ParticipantEditDialog/SelectOption";
import { useStoreSelector } from "store/hooks";
import { useFormikContext } from "formik";
import { IQualificationEntity, QualificationUtil } from "models/Qualification.model";
import {
  selectAllQualificationTypeEntities,
  selectLicensingClassesByQualificationName,
} from "store/domain-data/qualification-type/qualificationType";
import toString from "lodash/toString";
import isEmpty from "lodash/isEmpty";
import { useStore } from "react-redux";
import { RootState } from "store/types";
import { QualificationFormField } from "components/ParticipantEditDialog/models/QualificationFormField";

type Props = {};

const ParticipantQualificationTypeSelect: React.FC<Props> = () => {
  const { t } = useTranslation();
  const store = useStore<RootState>();
  const { values, handleChange, setFieldValue } = useFormikContext<IQualificationEntity>();

  const qualificationTypes = useStoreSelector(selectAllQualificationTypeEntities);

  const handleChangeQualificationType = React.useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const newValue = toString(event.target.value);
      setFieldValue(QualificationFormField.QualificationType, newValue);

      // Cascade change other form fields based on QualificationFormField.QualificationType change
      const availableLicensingClasses = selectLicensingClassesByQualificationName(store.getState(), newValue);
      if (isEmpty(availableLicensingClasses)) {
        setFieldValue(QualificationFormField.LicensingClasses, []);
      }
      if (!QualificationUtil.isOtherQualification(newValue)) {
        setFieldValue(QualificationFormField.OtherQualificationType, "");
      }

      handleChange(event);
    },
    [setFieldValue, store, handleChange]
  );

  return (
    <FlexBox direction="column" spacing={2}>
      <StyledHeader>{t(`Qualification type`)}</StyledHeader>
      <StyledSelect
        data-testid={"ParticipantQualificationTypeSelect"}
        name={QualificationFormField.QualificationType}
        variant="outlined"
        value={values.qualificationName}
        onChange={handleChangeQualificationType}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        }}
      >
        {qualificationTypes.map((qualificationType) => (
          <SelectOption
            key={qualificationType.name}
            title={qualificationType.displayName}
            value={qualificationType.name}
          >
            <StyledSelectOptionsContainer>{qualificationType.displayName}</StyledSelectOptionsContainer>
          </SelectOption>
        ))}
      </StyledSelect>
    </FlexBox>
  );
};

const StyledHeader = styled.span(
  ({ theme }) => css`
    font-weight: 600;
    font-size: 14px;
    color: ${theme.palette.objective.dark.neutral};
  `
);

const StyledSelectOptionsContainer = styled.span(
  ({ theme }) => css`
    display: flex;
    font-weight: 600;
    font-size: 16px;
  `
);

const StyledSelect = styled(Select)(
  ({ theme }) => css`
    .MuiSelect-root {
      color: ${theme.palette.objective.dark.night};
    }
  `
);

export default ParticipantQualificationTypeSelect;
