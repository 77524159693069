import React from "react";
import { BoxProps } from "@material-ui/core";
import Box from "components/Box/Box";
import { objectivePalette } from "styles/objectivePalette";
import { UserAvatarType } from "components/UserAvatar/UserAvatar.types";
import styled, { css } from "styled-components/macro";

type Props = BoxProps & {
  initials: string;
  rectSize: number;
  fontSize: number;
  charLimit?: number;
  type?: UserAvatarType;
};

const UserAvatar: React.FC<Props> = ({
  initials,
  rectSize,
  fontSize,
  charLimit = 2,
  type = UserAvatarType.Solid,
  ...boxProps
}) => {
  const avatar = React.useMemo(() => {
    const avatarTypes = new Map<string, { border: string; bgcolor: string }>([
      [UserAvatarType.Solid, { border: "none", bgcolor: objectivePalette.blue.main }],
      [UserAvatarType.Outlined, { border: `2px solid ${objectivePalette.blue.main}`, bgcolor: "transparent" }],
      [UserAvatarType.Dimmed, { border: "none", bgcolor: `${objectivePalette.light.allspice}` }],
    ]);
    return avatarTypes.get(type) as { border: string; bgcolor: string };
  }, [type]);

  // TODO: Refactor - get rid of Box for detailed styling, use styled-components instead
  return (
    <StyledBox
      width={rectSize}
      height={rectSize}
      borderRadius={rectSize}
      border={avatar.border}
      bgcolor={avatar.bgcolor}
      display="flex"
      justifyContent="center"
      alignItems="center"
      fontWeight={600}
      fontSize={fontSize}
      {...boxProps}
    >
      {initials?.slice(0, charLimit) || "?"}
    </StyledBox>
  );
};

export default UserAvatar;

const StyledBox = styled(Box)(
  ({ theme }) => css`
    color: ${theme.palette.objective.light.white};
    font-weight: 600;
  `
);
