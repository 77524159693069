import React, { ChangeEvent } from "react";
import styled, { css } from "styled-components/macro";
import { nanoid } from "@reduxjs/toolkit";
import FontIcon from "components/FontIcon/FontIcon";
import Guard from "components/Guard/Guard";

type Props = {
  label: string;
  checked: boolean;
  onChange?: (checked: boolean) => any;
  className?: string;
  fieldName?: string;
  disabled?: boolean;
  "data-testid"?: string;
};

const Switch: React.FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  ({ label, checked, disabled = false, fieldName, onChange, className, "data-testid": dataTestId }, ref) => {
    const inputId = React.useMemo(() => nanoid(9), []);

    const handleOnChange = React.useCallback(
      (evt: ChangeEvent<HTMLInputElement>) => {
        const shouldCheck = evt.target.checked;
        if (onChange) {
          onChange(shouldCheck);
        }
      },
      [onChange]
    );

    return (
      <div data-testid={dataTestId || "Switch"} ref={ref} className={className}>
        <StyledInput
          data-testid={dataTestId + "Input" || "SwitchInput"}
          id={inputId}
          type="checkbox"
          checked={checked}
          name={fieldName}
          disabled={disabled}
          onChange={handleOnChange}
        />
        <StyledLabel htmlFor={inputId} disabled={disabled}>
          <StyledPill checked={checked}>
            <StyledRoundFloat checked={checked} disabled={disabled} />
            <StyledMarkContainer checked={checked}>
              <Guard condition={checked} fallback={<StyledUncheckedMark name={"close"} />}>
                <StyledCheckedMark name={"check"} />
              </Guard>
            </StyledMarkContainer>
          </StyledPill>
          <StyledLabelText checked={checked} disabled={disabled}>
            {label}
          </StyledLabelText>
        </StyledLabel>
      </div>
    );
  }
);

const StyledInput = styled.input(
  ({ theme }) => css`
    display: none;
    //// Screen reader only
    //position: absolute;
    //left: -10000px;
    //top: auto;
    //width: 1px;
    //height: 1px;
    //overflow: hidden;
  `
);

const StyledMarkContainer = styled.div<{ checked: boolean }>(
  ({ theme, checked }) => css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;

    ${!checked &&
    css`
      justify-content: flex-end;
      padding-right: 12px;
    `}

    ${checked &&
    css`
      justify-content: flex-start;
      padding-left: 12px;
    `}
  `
);

const StyledCheckedMark = styled(FontIcon)(
  ({ theme }) => css`
    font-size: 12px;
    color: ${theme.palette.objective.light.white};
  `
);

const StyledUncheckedMark = styled(FontIcon)(
  ({ theme }) => css`
    font-size: 12px;
    color: ${theme.palette.objective.light.white};
  `
);

const StyledPill = styled.div<{ checked: boolean }>(
  ({ theme, checked }) => css`
    width: 56px;
    height: 22px;
    border-radius: 9999em;
    position: relative;

    -webkit-transition: background-color 0.25s ease;
    transition: background-color 0.25s ease;

    background-color: #6c7789;

    ${checked &&
    css`
      background-color: #27ae60;
    `}
  `
);

const StyledRoundFloat = styled.div<{ checked: boolean; disabled: boolean }>(
  ({ theme, checked, disabled }) => css`
    background-color: ${theme.palette.objective.light.white};
    border-radius: 50%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    content: "";
    cursor: pointer;
    height: 18px;
    left: 2px;
    position: absolute;
    top: 2px;
    -webkit-transition: left 0.25s ease;
    transition: left 0.25s ease;
    width: 18px;

    ${checked &&
    css`
      left: 36px;
    `}

    ${disabled &&
    css`
      background: unset;
      box-shadow: unset;
      border: 2px solid rgba(255, 255, 255, 0.75);
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    `}
  `
);

const StyledLabelText = styled.div<{ checked: boolean; disabled: boolean }>(
  ({ theme, checked }) => css`
    font-size: 14px;
    line-height: 22px;
    color: ${theme.palette.objective.dark.fiordland};

    ${checked &&
    css`
      font-weight: 600;
      color: ${theme.palette.objective.dark.night};
    `}
  `
);

const StyledLabel = styled.label<{ disabled: boolean }>(
  ({ theme, disabled }) => css`
    display: flex;
    ${theme.mixins.flexGap("12px")}
    align-items: flex-start;
    cursor: pointer;

    ${disabled &&
    css`
      cursor: not-allowed;
    `}
  `
);

export default Switch;
