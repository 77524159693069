import React from "react";
import { useTranslation } from "react-i18next";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { addQualification, IParticipantBufferEntity } from "store/app-state/participant-buffer/participantBuffer";
import ActionItem, { ActionItemVariant } from "components/ActionSection/ActionItem";
import { selectAllQualificationTypeEntities } from "store/domain-data/qualification-type/qualificationType";
import first from "lodash/first";

export const AddQualificationButton: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const qualificationTypes = useStoreSelector(selectAllQualificationTypeEntities);

  const handleClickAddQualification = React.useCallback(() => {
    const defaultQualificationType = first(qualificationTypes);
    if (!defaultQualificationType) {
      return;
    }

    const qualification: IParticipantBufferEntity["qualifications"][0] = {
      qualificationName: defaultQualificationType.name,
      licensingClasses: [],
      qualificationNumber: "",
      otherQualificationType: "",
      isValid: false,
    };

    dispatch(addQualification(qualification));
  }, [dispatch, qualificationTypes]);

  return (
    <ActionItem
      item={{
        icon: "plus-circle",
        title: t("Add a qualification"),
        variant: ActionItemVariant.Button,
        onClick: handleClickAddQualification,
      }}
    />
  );
};
