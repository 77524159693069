import React from "react";
import FlexBox from "components/FlexBox/FlexBox";
import { Autocomplete } from "@material-ui/lab";
import { Box, capitalize, FormControl, FormHelperText, InputLabel, Paper } from "@material-ui/core";
import { useFormikContext } from "formik";
import { nanoid } from "@reduxjs/toolkit";
import debounce from "lodash/debounce";
import trim from "lodash/trim";
import styled, { css } from "styled-components/macro";
import OutlinedInput from "components/OutlinedInput/OutlinedInput";
import { ContactUtil, IContactEntity } from "models/Contact.model";
import { useStoreSelector } from "store/hooks";
import { selectAllContactEntities } from "store/domain-data/contact/contact";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import Guard from "components/Guard/Guard";

export enum FieldSearchType {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
}

type Props = {
  label: string;
  fieldName: string;
  fieldToSearch: FieldSearchType;
  initialValue?: string;
  required?: boolean;
  onLookupSelection: (contact: IContactEntity) => void;
  customOptionsFilter?: (contact: IContactEntity, currentText: string) => boolean;
};

const FormikContactLookupInput: React.FC<Props> = ({
  label,
  fieldName,
  fieldToSearch,
  initialValue,
  required,
  onLookupSelection,
  customOptionsFilter,
}) => {
  const { touched, errors, setFieldValue, handleChange, handleBlur } = useFormikContext<any>();

  const [contactSearchInputValue, setContactSearchInputValue] = React.useState("");
  const [isOptionListOpen, setIsOptionListOpen] = React.useState(false);
  const [contactOptions, setContactOptions] = React.useState<IContactEntity[]>([]);
  const latestSelectedContactRef = React.useRef<IContactEntity>();
  const isAutocompleteInputFocusedRef = React.useRef(false);

  const contacts = useStoreSelector((state) => selectAllContactEntities(state));

  const debouncedSearch = React.useMemo(
    () =>
      debounce(async (keyword: string) => {
        const matchingContacts = contacts.filter((contact) => {
          const contactField = contact[fieldToSearch].toLowerCase();
          const searchStr = keyword.toLowerCase();

          return contactField.startsWith(searchStr) && (!customOptionsFilter || customOptionsFilter(contact, keyword));
        });

        if (!isAutocompleteInputFocusedRef.current) {
          return;
        }

        setContactOptions(matchingContacts);
        setIsOptionListOpen(true);
      }, 1000),
    [contacts, customOptionsFilter, fieldToSearch]
  );

  // Contact search
  const debouncedLookupContact = React.useCallback((keyword: string) => debouncedSearch(keyword), [debouncedSearch]);

  const clearContactSearch = React.useCallback(() => {
    setContactSearchInputValue("");
  }, []);

  const handleContactSearchInputChange = React.useCallback(
    (event: any, value: string, reason: string) => {
      handleChange(event);
      setContactSearchInputValue(value);

      if (!value || value.length < 2 || reason === "reset") {
        return;
      }

      debouncedLookupContact(value);
    },
    [debouncedLookupContact, handleChange]
  );

  const handleContactSearchSelectionChange = React.useCallback(
    async (event: any, value: IContactEntity | string | null, reason: string) => {
      setIsOptionListOpen(false);

      if (!value || typeof value === "string") {
        return;
      }

      latestSelectedContactRef.current = value;
      onLookupSelection(value);
    },
    [onLookupSelection]
  );

  const handleContactSearchInputBlur = React.useCallback(
    (evt: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setIsOptionListOpen(false);

      // If input value is empty, clear the contact details
      if (!trim(evt.target.value)) {
        latestSelectedContactRef.current = undefined;
        clearContactSearch();
        return;
      }

      handleBlur(evt);
    },
    [clearContactSearch, handleBlur]
  );

  // - Effects

  // Reset autocomplete and form values when init / re-init
  React.useEffect(() => {
    if (!initialValue) {
      return;
    }

    latestSelectedContactRef.current = undefined;

    setContactSearchInputValue(initialValue);
    setFieldValue(fieldToSearch, initialValue);
  }, [initialValue, fieldToSearch, setFieldValue]);

  const inputId = fieldName + "-" + nanoid(5);
  const hasError = touched[fieldName] && Boolean(errors[fieldName]);

  return (
    <FlexBox direction="column" spacing={3} data-testid={`FormikContactLookupInput-${fieldName}`}>
      <FormControl fullWidth={true} hiddenLabel={true} variant={"outlined"} error={hasError} required={required}>
        <InputLabel htmlFor={inputId} shrink={false}>
          {label}
        </InputLabel>
        <Autocomplete
          data-testid="FormikContactLookupInputAutocomplete"
          id={inputId}
          freeSolo={true}
          openOnFocus={false}
          selectOnFocus={false}
          blurOnSelect={true}
          onChange={handleContactSearchSelectionChange}
          onInputChange={handleContactSearchInputChange}
          inputValue={contactSearchInputValue}
          open={isOptionListOpen}
          PaperComponent={StyledAutocompletePopupPaper}
          ListboxComponent={StyledAutocompleteListbox}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <OutlinedInput
                {...params.inputProps}
                id={inputId}
                name={fieldName}
                error={hasError}
                fullWidth={true}
                type="search"
                autoComplete="off"
                onBlur={(evt) => {
                  isAutocompleteInputFocusedRef.current = false;

                  const inputProps: any = params.inputProps;
                  if (inputProps.onBlur) {
                    inputProps.onBlur(evt);
                  }

                  handleContactSearchInputBlur(evt);

                  // Trim Input value
                  const value = trim(evt.target.value);
                  setFieldValue(fieldName, value);
                  setContactSearchInputValue(value);
                }}
                onFocus={(evt) => {
                  isAutocompleteInputFocusedRef.current = true;

                  const inputProps: any = params.inputProps;
                  if (inputProps.onFocus) {
                    inputProps.onFocus(evt);
                  }
                }}
              />
            </div>
          )}
          options={contactOptions}
          getOptionLabel={(option) => option[fieldToSearch]}
          getOptionSelected={(option, value) => option.id === value.id}
          renderOption={(option, { inputValue }) => {
            const matches = match(ContactUtil.getDisplayName(option), inputValue);
            const parts = parse(ContactUtil.getDisplayName(option), matches);

            return (
              <FlexBox data-testid={"ContactOption-" + option[fieldToSearch]} direction={"column"} spacing={1}>
                <Box>
                  {parts.map((part, index) => (
                    <Box component={"span"} key={index} fontWeight={part.highlight ? 700 : 400}>
                      {part.text}
                    </Box>
                  ))}
                </Box>
                <FlexBox direction={"column"} spacing={0.5}>
                  <Guard condition={option.organisation}>
                    <Box fontSize={11}>{option.organisation}</Box>
                  </Guard>
                  <Guard condition={option.email}>
                    <Box fontSize={11}>{option.email}</Box>
                  </Guard>
                </FlexBox>
              </FlexBox>
            );
          }}
        />

        {hasError && (
          <FormHelperText error={true} data-testid={`InputError${capitalize(fieldName)}`}>
            {errors[fieldName]}
          </FormHelperText>
        )}
      </FormControl>
    </FlexBox>
  );
};

const StyledAutocompletePopupPaper = styled(Paper)(
  ({ theme }) => css`
    box-shadow: ${theme.shadows[6]};
  `
);

const StyledAutocompleteListbox = styled.ul(
  ({ theme }) => css`
    & > li {
      border-left: 4px solid transparent;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    & > li:hover {
      border-left: 4px solid ${theme.palette.primary.main};
      background: ${theme.palette.objective.blue.light};
    }
  `
);

export default FormikContactLookupInput;
