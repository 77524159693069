import React from "react";
import InformationDialog from "components/InformationDialog/InformationDialog";
import styled, { css } from "styled-components/macro";

type Props = {
  participantTypeDisplayNames: string;
  isOpen: boolean;
  onClose: (confirmed: boolean) => void;
};

const ParticipantCannotRemoveInformationDialog: React.FC<Props> = ({
  participantTypeDisplayNames,
  isOpen,
  onClose,
}) => {
  return (
    <InformationDialog title={`Required ${participantTypeDisplayNames} type`} isOpen={isOpen} onClose={onClose}>
      <StyledContent>
        <p>A valid application needs to have {participantTypeDisplayNames} type.</p>
        <br />
        <p>If you wish to remove required types you will have to re-assign to another participant.</p>
        <br />
        <p>
          To re-assign a role you will need to select the <b>3 dot menu &gt; Edit Types and Access</b> then set the
          required types to other participants.
        </p>
      </StyledContent>
    </InformationDialog>
  );
};

const StyledContent = styled.div(
  ({ theme }) => css`
    max-width: 600px;
    padding-bottom: 24px;
  `
);

export default ParticipantCannotRemoveInformationDialog;
