import React from "react";
import { capitalize, FormControl, FormHelperText, InputLabel } from "@material-ui/core";
import { useFormikContext } from "formik";
import Guard from "components/Guard/Guard";
import OutlinedInput from "components/OutlinedInput/OutlinedInput";
import trim from "lodash/trim";
import styled, { css } from "styled-components/macro";

const nanoid = require("nanoid");

type Props = {
  label: string;
  fieldName: string;
  // TODO: we probably don't need inputType anymore - each other type of input will have its own component
  inputType?: "text" | "email";
  disabled?: boolean;
  required?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
};

// TODO: Split: FormikTextInput + FormikTextArea
const FormikTextInput: React.FC<Props> = ({
  label,
  fieldName,
  inputType = "text",
  disabled = false,
  required = false,
  onBlur,
}) => {
  const { touched, errors, handleChange, handleBlur: formikHandleBlur, setFieldValue, values } = useFormikContext<
    any
  >();

  const inputId = React.useMemo(() => fieldName + "-" + nanoid(5), [fieldName]);
  const hasError = touched[fieldName] && Boolean(errors[fieldName]);
  const handleBlur = React.useCallback(
    (evt: React.FocusEvent<HTMLInputElement>) => {
      // Trim text input
      setFieldValue(fieldName, trim(evt.target.value));

      // Custom blur handler
      if (onBlur) {
        onBlur(evt);
      }

      // General blur handler
      formikHandleBlur(evt);
    },
    [formikHandleBlur, onBlur, setFieldValue, fieldName]
  );

  return (
    <FormControl
      fullWidth={true}
      error={hasError}
      hiddenLabel={true}
      required={required}
      disabled={disabled}
      variant={"outlined"}
    >
      <StyledInputLabel htmlFor={inputId} shrink={false}>
        {label}
      </StyledInputLabel>

      <Guard condition={disabled}>
        <OutlinedInput id={inputId} name={fieldName} type={inputType} value={values[fieldName]} disabled={true} />
      </Guard>

      <Guard condition={!disabled}>
        <OutlinedInput
          id={inputId}
          name={fieldName}
          type={inputType}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[fieldName]}
          error={hasError}
        />
      </Guard>

      {hasError && (
        <FormHelperText error={true} data-testid={`InputError${capitalize(fieldName)}`}>
          {errors[fieldName]}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormikTextInput;

const StyledInputLabel = styled(InputLabel)(
  ({ theme }) => css`
    font-size: 16px;
    font-weight: 400;
    color: ${theme.palette.objective.dark.night};
  `
);
