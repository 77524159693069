import React from "react";
import { useStoreSelector } from "store/hooks";
import { selectCurrentQualifications } from "store/app-state/participant-buffer/participantBuffer";
import ParticipantQualificationForm from "./ParticipantQualificationForm";
import ParticipantHeadlineSimple from "components/ParticipantEditDialog/ParticipantHeadlineSimple";
import styled, { css } from "styled-components/macro";
import { AddQualificationButton } from "components/ParticipantEditDialog/AddQualificationButton";
import { Divider } from "@material-ui/core";

type Props = {};

const ParticipantQualificationList: React.FC<Props> = () => {
  const currentQualifications = useStoreSelector(selectCurrentQualifications);

  return (
    <StyledListContainer data-testid="ParticipantQualificationList">
      <ParticipantHeadlineSimple />

      {currentQualifications.map((qualification, index) => (
        <React.Fragment key={`${qualification.qualificationName}-${index}`}>
          {index > 0 && (
            <StyledDividerContainer>
              <Divider />
            </StyledDividerContainer>
          )}
          <ParticipantQualificationForm index={index} />
        </React.Fragment>
      ))}

      <AddQualificationButton />
    </StyledListContainer>
  );
};

const StyledListContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 24px 48px;
    gap: ${theme.mixins.flexGap("8px")};
  `
);

const StyledDividerContainer = styled.div(
  () => css`
    padding: 16px 0;
  `
);

export default ParticipantQualificationList;
